import * as React from 'react';
import * as ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/styles';
import App from './App';
import theme from './theme';
import { Store } from './store';


const rootElement = document.getElementById('root');
const root = ReactDOM.createRoot(rootElement);

root.render(
 <ThemeProvider theme={ theme }>
  {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */ }
  <CssBaseline />
  <Provider store={ Store }>
   <App />
  </Provider>
 </ThemeProvider>
);
