const initialState = {
 user_id: null,
 email: null,
 name: null,
}

export const userReducer = (state = initialState, action) => {
 switch (action.type) {
  case 'USER_AUTH_ACTION':
   return {
    ...state,
    user_id: action.user_id,
    name: action.name,
    email: action.email
   }
 
  case 'LOGOUT':
   return {
    ...state,
    user_id: null,
    name: null,
    email: null
   }
  default:
   return state;
 }
}