import React from 'react'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import { Button, Paper, Icon } from '@mui/material';
import { getRequest, postRequest, patchRequest, deleteRequest } from '../../requests/requests';
import { CustomDataGrid } from '../../components/CustomDataGrid/CustomDataGrid';
import { ptBR } from '@mui/x-data-grid';

import ClinicForm from './ClinicForm';
import ClinicUsersForm from './ClinicUsersForm';
import Settings from './Settings';
import { columns } from './tableProps';


class Clinics extends React.Component {
 constructor(props) {
  super(props)

  this.state = {
   clinics: [],
   currentClinic: null,
   isLoading: true,
   openEdit: false,
   openUsersEdit: false
  }

  this.columns = columns

  this.requestForClinics = this.requestForClinics.bind(this)
  this.requestForClinicsAndRefreshUI = this.requestForClinicsAndRefreshUI.bind(this)
  this.saveClinic = this.saveClinic.bind(this)
  this.refreshUI = this.refreshUI.bind(this)
  this.commit = this.commit.bind(this)
  this.openNewForm = this.openNewForm.bind(this)
  this.openEditForm = this.openEditForm.bind(this)
  this.closeEditForm = this.closeEditForm.bind(this)
  this.openUsersEditForm = this.openUsersEditForm.bind(this)
  this.closeUsersEditForm = this.closeUsersEditForm.bind(this)
  this.openSettingsEditForm = this.openSettingsEditForm.bind(this)
  this.closeSettingsEditForm = this.closeSettingsEditForm.bind(this)


 }

 componentDidMount() {
  this.requestForClinicsAndRefreshUI()
 }

 componentDidUpdate(prevProps, prevState) {

 }

 requestForClinics() {
  return getRequest('clinics2')
 }

 requestForClinicsAndRefreshUI() {
  this.setState({
   isLoading: true
  }, () => {
   this.requestForClinics().then(
    (response) => {
     this.setState({
      clinics: response.data.clinics2,
      isLoading: false
     })
    }
   )
    .catch(err => console.log(err))
  })
 }

 commit(clinic, origUsers, formName) {

  switch (formName) {
   case 'ClinicForm':
    this.closeEditForm()
    this.openUsersEditForm({ row: clinic })
    break;

   case 'ClinicUsersForm':
    this.closeUsersEditForm()
    this.openSettingsEditForm({ row: clinic })
    break;

   default:
    this.saveClinic(clinic, origUsers).then((response) => {
     this.refreshUI(response.data)
     toast.success(`Clínica alterada com sucesso!`)
    })
     .catch((err) => {
      toast.error('Erro')
     })

  }

 }

 saveClinic(clinic, origUsers) {
  const address = clinic.address
  const hasAddress = address && (address.name || address.number ||
   address.complement || address.neighborhood || address.postal_code ||
   address.state || address.city)

  const settings = clinic.settings
  const hasSettings = settings && (settings.length > 0)

  const users = clinic.users
  const hasUsers = users && (users.length > 0)

  console.log("*** Clinics.saveClinic - clinic: ", JSON.stringify(clinic))

  if (clinic.id) {
   const usersToBeDestroyed =
    origUsers.filter(origUser =>
     !clinic.users.some(user => user.id == origUser.id)
    )

   const clinicSave = {
    name: clinic.name,
    cpf: clinic.cpf,
    cnpj: clinic.cnpj,
    email: clinic.email || null,
    phone: clinic.phone,
    cellphone: clinic.cellphone
   }

   if (hasAddress) {
    clinicSave.address_attributes = {
     id: address ? address.id : null,
     postal_code: address ? address.postal_code : null,
     name: address ? address.name : null,
     number: address ? address.number : null,
     complement: address ? address.complement : null,
     state: address ? address.state : null,
     city: address ? address.city : null,
    }
   }

   if (hasUsers) {
    clinicSave.users_attributes = users
     .map(user => {
      const { id, ...userNoId } = user
      const userSameId = origUsers.find(origUser => origUser.id == user.id)

      return userSameId ? { id: userSameId.id, ...userNoId } : { ...userNoId, type: userNoId.role }
     })
     .concat(usersToBeDestroyed.map(user => (
      { id: user.id, _destroy: true }
     )))
   }

   if (hasSettings) {
    clinicSave.settings_attributes = settings
   }

   return patchRequest('clinics2', {
    clinic: clinicSave
   }, `id=${clinic.id}`)
  }

  const clinicSave = {
   name: clinic.name,
   cpf: clinic.cpf,
   cnpj: clinic.cnpj,
   email: clinic.email || null,
   phone: clinic.phone,
   cellphone: clinic.cellphone
  }

  if (hasAddress) {
   clinicSave.address_attributes = {
    name: address.name,
    number: address.number,
    complement: address.complement,
    neighborhood: address.neighborhood,
    postal_code: address.postal_code,
    state: address.state,
    city: address.city
   }
  }

  if (hasUsers) {
   clinicSave.users_attributes = users.map(user => {
    const { id, ...userNoId } = user
    return { ...userNoId, type: userNoId.role }
   })
  }

  if (hasSettings) {
   clinicSave.settings_attributes = settings
  }

  return postRequest('clinics2', {
   clinic: clinicSave
  })


 }

 /* Método genérico de atualização de interface, controlada por variável de estado array 'clinics',
 conforme  arrays 'added', 'changed' e 'deleted' retornados pelo backend */
 refreshUI({ created, updated, deleted }) {

  this.setState(state => {
   let { clinics } = state;
   let clinicsUpdate = [...clinics]

   if (created) {
    clinicsUpdate.push(...created)
   }

   if (updated) {
    clinicsUpdate = clinicsUpdate.map(clinic => {
     let updatedClinic = updated.find(cl => cl.id == clinic.id)
     return (updatedClinic == undefined) ? clinic : updatedClinic
    }
    )
   }

   if (deleted) {
    clinicsUpdate = clinicsUpdate.filter(clinic => {
     let deletedClinic = deleted.find(cl => cl.id == clinic.id)
     return (deletedClinic == undefined) ? true : false
    }
    )
   }

   return {
    clinics: clinicsUpdate,
    openEdit: false,
    openUsersEdit: false,
    openSettingsEdit: false
   }
  })

 }

 closeEditForm() {
  this.setState({ openEdit: false })
 }

 openNewForm() {
  this.setState({ openEdit: true, currentClinic: null })
 }

 openEditForm(props) {
  this.setState({ openEdit: true, currentClinic: props.row })
 }

 closeUsersEditForm() {
  this.setState({ openUsersEdit: false, currentClinic: null })
 }

 openUsersEditForm(props) {
  this.setState({ openUsersEdit: true, currentClinic: props.row })
 }

 closeSettingsEditForm() {
  this.setState({ openSettingsEdit: false })
 }

 openSettingsEditForm(props) {
  this.setState({ openSettingsEdit: true, currentClinic: props.row })
 }


 render() {
  const { clinics, currentClinic, openEdit, openUsersEdit, openSettingsEdit, isLoading } = this.state

  const columns = this.columns;

  const actions = [
   {
    icon: 'edit',
    onClick: (props) => {
     this.openEditForm(props)
    }
   },
   {
    icon: 'person',
    onClick: (props) => {
     this.openUsersEditForm(props)
    }
   },
   {
    icon: 'settings',
    onClick: (props) => {
     this.openSettingsEditForm(props)
    }
   },
   {
    render: () => <Button
     variant="contained"
     color="primary"
     size="small"
     startIcon={ <Icon>add_circle</Icon> }
     onClick={ () => this.openNewForm() }
     style={ { whiteSpace: 'nowrap' } }
    >
     Nova Clínica
    </Button>,
    isFreeAction: true
   }
  ]

  return (
   <>
    <Paper>
     <div style={ { height: 600, width: '100%' } }>


      <div style={ { display: 'flex', height: '100%' } }>
       <div style={ { flexGrow: 1 } }>
        <CustomDataGrid title='Clínicas' data={ clinics }
         columns={ columns } actions={ actions }
         localeText={ ptBR.components.MuiDataGrid.defaultProps.localeText } disableColumnMenu
         loading={ isLoading }

        />
       </div>
      </div>
     </div>

     <ClinicForm
      open={ openEdit }
      onClose={ this.closeEditForm }
      onCommit={ this.commit }
      clinicData={ currentClinic }
     />

     <ClinicUsersForm
      open={ openUsersEdit }
      onClose={ this.closeUsersEditForm }
      onCommit={ this.commit }
      clinicData={ currentClinic }
     />

     <Settings
      open={ openSettingsEdit }
      onClose={ this.closeSettingsEditForm }
      onCommit={ this.commit }
      clinicData={ currentClinic }
     />

     <ToastContainer
      position="top-center"
      autoClose={ 5000 }
      draggable={ false }
      hideProgressBar={ true }
     />

    </Paper>
   </>
  )
 }

}

export default Clinics