import React from 'react';
import { Typography, Icon, TextField, InputAdornment, Paper, Card, CardHeader, CardContent } from '@mui/material';
import { GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';

class SearchField extends React.Component {

 constructor(props) {
  super(props)
 }

 render() {
  const { placeholder, onChange, value } = this.props

  return (
   <TextField
    type="search"
    inputProps={ {
     style: { height: '2em' },
     placeholder: placeholder
    } }
    InputProps={ {
     style: { marginRight: '1em' },
     startAdornment: (
      <InputAdornment position="start">
       <Icon>search</Icon>
      </InputAdornment>
     ),
    } }
    onChange={ event => onChange(event.target.value) }
   />
  )
 }
}

export default class DataGridToolBar extends React.Component {
 constructor(props) {
  super(props)
 }

 /* shouldComponentUpdate(nextProps, nextState) {
  return false
 } */

 render() {
  const { title, actions, options, onFilter, onGetFilterValue, onSetFilterValue, searchString } = this.props

  return (
   <GridToolbarContainer
    style={ { display: 'flex', flexDirection: 'column', alignItems: 'start', width: '100%' } }>

    { title ? <Typography
     variant="h6"
     style={ {
      whiteSpace: "nowrap",
      overflow: "hidden",
      padding: 10
     } }
    >
     { title }
    </Typography> : ""
    }

    <div style={ { display: 'flex', width: '100%' } }>
     {

      /* Definições de filtros */
      <div style={ {
       display: 'flex', gap: '10px', padding: '10px', alignItems: 'top',
        //backgroundColor: '#ff0000', height: '200px',
       flexGrow: 1, justifyContent: 'flex-end'
      } }>
       {
        options && options.filterDefs && options.filterDefs.map(filterDef => filterDef.render(onGetFilterValue, onSetFilterValue))
       }
      </div>
     }



     <div style={ {
      display: 'flex', flexGrow: 1, gap: '10px', padding: '10px',
      justifyContent: 'flex-end', alignItems: 'center', flexGrow: 0
     } }>
      {
       options && options.search &&
       <SearchField placeholder={ options.search.placeholder }
        onChange={ onFilter }
        value={ searchString } />
      }

      {
       options && options.export &&
       <GridToolbarExport csvOptions={ options.export.csvOptions } variant="contained" />
      }

      <div style={ {
       display: 'flex', gap: '10px', padding: '10px', alignItems: 'center',
       /* backgroundColor: '#ff0000', */
       flexGrow: 1, justifyContent: 'flex-end'
      } }>
       {
        actions.map(action => action.render())
       }
      </div>
     </div>

    </div>


   </GridToolbarContainer >
  )

 }
}

