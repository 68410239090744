import React, { Component } from 'react';
import memoize from "memoize-one";

import {
 Dialog, DialogActions, DialogContent, DialogTitle,
 Button, Typography, Tabs, Tab
} from '@mui/material';
import { Person, SupervisorAccount, Error } from '@mui/icons-material';
import TabPanel from '../../components/Panels/TabPanel';
import UsersGrid from './UsersGrid';
import ProfessionalForm from '../Professionals/ProfessionalForm';
import { LayoutDialog, LayoutDialogActions, LayoutDialogContent } from '../../components/Dialogs';
import { EMAIL_REGEX, PASSWORD_REGEX } from '../../components/Utils/regexs';

const SaveDialogContent = props => (
 <>
  <Typography style={ { fontWeight: 'bold' } }>
   As seguintes edições foram realizadas. Deseja continuar?
  </Typography>
  <Typography style={ { fontSize: '0.5em' } }>
   { JSON.stringify(props.users) }
  </Typography>
 </>
)

const ValidationDialogContent = props => (
 <div style={ { display: 'flex', flexDirection: 'column' } }>
  <div style={ { display: 'flex', flexDirection: 'row', gap: '1em', alignItems: 'center' } }>
   <Error fontSize='large' />

   <Typography style={ { fontWeight: 'bold' } }>
    Os seguintes erros foram encontrados:
   </Typography>

  </div>
  {/* <div>
   <Typography style={ { fontSize: '0.5em' } }>
    { JSON.stringify(props.users) }
   </Typography>
  </div> */}

  <ul style={ { fontSize: '0.8em' } }>
   {
    props.errors.map(error =>
     <li>
      { error }
     </li>
    )
   }

  </ul>

 </div>
)

const TAB_PANEL_ADMINISTRATOR = 0
const TAB_PANEL_SECRETARY = 1
const TAB_PANEL_PROFESSIONAL = 2

class ClinicUsersForm extends Component {

 filterUsers = memoize(
  (users, role) => users.filter(user => user.role == role)
 );

 withOrdersAssigned = function (users) {
  let admOrder = 1, secOrder = 1, profOrder = 1

  let newUsers = [...users]

  newUsers.forEach(user => {
   switch (user.role) {
    case 'Administrator': user.order = admOrder++
     break;

    case 'Secretary': user.order = secOrder++
     break;

    case 'Professional': user.order = profOrder++
     break;
   }
  })

  return newUsers
 }

 removeProperties = function (users) {
  let newUsers = users.map(user => {
   const { order, confirmPassword, ...newUser } = user
   return newUser
  })

  return newUsers
 }

 constructor(props) {
  super(props)

  console.log("*** ClinicUsersForm.constructor - props.clinicData", JSON.stringify(props.clinicData))


  this.restoreUsersDialogRef = React.createRef()
  this.saveDialogRef = React.createRef()
  this.validationDialogRef = React.createRef()

  this.state = {
   panel: TAB_PANEL_ADMINISTRATOR,
   clinicChanges: {},
   openUserEdit: false,
   currentUser: {},
   errors: {}
  }

  this.setClinicChanges = this.setClinicChanges.bind(this)
  this.setErrors = this.setErrors.bind(this)
  this.assignOrders = this.assignOrders.bind(this)
  this.mergeUsersChanges = this.mergeUsersChanges.bind(this)
  this.excludeUser = this.excludeUser.bind(this)
  this.restoreUsers = this.restoreUsers.bind(this)
  this.onRestoreUsers = this.onRestoreUsers.bind(this)
  this.onSave = this.onSave.bind(this)
  this.toggleUsersPanel = this.toggleUsersPanel.bind(this)
  this.openUserEditForm = this.openUserEditForm.bind(this)
  this.closeUserEditForm = this.closeUserEditForm.bind(this)
  this.fieldError = this.fieldError.bind(this)
  this.validateForm = this.validateForm.bind(this)
 }


 componentDidMount() {

 }


 componentDidUpdate(prevProps, prevState) {
  const { open } = this.props

  if (prevProps.open && !open) {
   this.setState({
    clinicChanges: {},
    openUserEdit: false,
    currentUser: {},
    panel: TAB_PANEL_ADMINISTRATOR
   })
  }

  if (!prevProps.open && open) {
   this.assignOrders()
  }
 }

 render() {
  const { open, onClose, onCommit, clinicData } = this.props
  const { panel, clinicChanges, openUserEdit, currentUser, errors } = this.state

  const clinic = {
   ...clinicData,
   ...clinicChanges
  }

  const origUsers = clinicData ? clinicData.users : []

  const isNewClinic = (clinicData == null) || (clinicData.id == undefined)

  const users = clinic.users

  console.log("*** ClinicUsersForm.render - users: ", JSON.stringify(users))

  /* const administrators = users ? users.filter(user => user.role == 'Administrator') : []
  const secretaries = users ? users.filter(user => user.role == 'Secretary') : []
  const professionals = users ? users.filter(user => user.role == 'Professional') : [] */

  const administrators = users ?
   this.filterUsers(users, "Administrator") : []
  const secretaries = users ?
   this.filterUsers(users, "Secretary") : []
  const professionals = users ?
   this.filterUsers(users, "Professional") : []

  /* Identificador provisório 'id' para anexar ao próximo usuário adicionado, a ser passado
 para o componente 'UsersGrid', para satisfazer o componente 'DataGrid' utilizado neste.
 Não deve ser passado ao backend  */
  /* const nextAdmId = administrators ? (administrators.length + 1) : 1
  const nextSecId = secretaries ? (secretaries.length + 1) : 1
  const nextProfId = professionals ? (professionals.length + 1) : 1 */

  return (
   <Dialog aria-labelledby="simple-dialog-title"
    open={ open } onClose={ onClose } maxWidth='md' fullWidth>
    <DialogTitle id="simple-dialog-title">
     { `Usuários da clínica "${clinic.name}"` }
    </DialogTitle>
    <DialogContent>

     <Tabs value={ panel } indicatorColor=""
      onChange={ (event, newValue) => this.toggleUsersPanel(newValue) }
      aria-label="controle de usuários" centered>

      <Tab icon={ <SupervisorAccount /> } label="Administradores"
       aria-label="administrators" value={ TAB_PANEL_ADMINISTRATOR } />

      <Tab icon={ <Person /> } label="Secretários"
       aria-label="secretaries" value={ TAB_PANEL_SECRETARY } />

      <Tab icon={ <Person /> } label="Profissionais"
       aria-label="professionals" value={ TAB_PANEL_PROFESSIONAL } />

     </Tabs>
     <TabPanel value={ panel } index={ TAB_PANEL_ADMINISTRATOR }>
      <UsersGrid users={ administrators } role="Administrator"
       onChange={ this.mergeUsersChanges } onExclude={ this.excludeUser }
       onEdit={ this.openUserEditForm } onRestore={ isNewClinic ? null : this.onRestoreUsers } />
     </TabPanel>

     <TabPanel value={ panel } index={ TAB_PANEL_SECRETARY }>
      <UsersGrid users={ secretaries } role="Secretary"
       onChange={ this.mergeUsersChanges } onExclude={ this.excludeUser }
       onEdit={ this.openUserEditForm } onRestore={ isNewClinic ? null : this.onRestoreUsers } />

     </TabPanel>

     <TabPanel value={ panel } index={ TAB_PANEL_PROFESSIONAL }>
      <UsersGrid users={ professionals } role="Professional"
       onChange={ this.mergeUsersChanges } onExclude={ this.excludeUser }
       onEdit={ this.openUserEditForm } onRestore={ isNewClinic ? null : this.onRestoreUsers } />

     </TabPanel>

     <ProfessionalForm
      open={ openUserEdit }
      onClose={ this.closeUserEditForm }
      onSave={ professional => {
       this.mergeUsersChanges([{ ...professional, password: '1234567' }])
       this.closeUserEditForm()
      }
      }
      professionalData={ currentUser }
     />

     <LayoutDialog
      ref={ this.restoreUsersDialogRef }
      content={ LayoutDialogContent }
      contentProps={ {
       type: 'warning',
       text: 'Restaurar usuários? Todas as edições serão perdidas.'
      } }
      actions={ LayoutDialogActions }
      actionsProps={ {
       confirm_button: "Sim",
       dismiss_button: "Não",
       onAction: this.restoreUsers
      } }
     />

     <LayoutDialog
      ref={ this.validationDialogRef }
      content={ ValidationDialogContent }
      contentProps={ {
       errors: errors.users,
       users: clinic.users
      } }
      actions={ LayoutDialogActions }
      actionsProps={ {
       confirm_button: "OK",
       onAction: () => { }
      } }
     />

     <LayoutDialog
      ref={ this.saveDialogRef }
      content={ SaveDialogContent }
      contentProps={ {
       users: clinic.users
      } }
      actions={ LayoutDialogActions }
      actionsProps={ {
       confirm_button: "Sim",
       dismiss_button: "Não",
       onAction: () => {
        onCommit(
         /* {
          ...clinic,
          users: this.removeProperties(clinic.users)
         }, */
         clinic,
         origUsers,
         isNewClinic ? this.constructor.name : null
        )
       }
      } }
     />

     <DialogActions>
      <Button onClick={ onClose } color="secondary">
       Cancelar
      </Button>
      <Button onClick={ (event) => this.onSave() }
       color="primary">
       { isNewClinic ? "Próximo" : "Salvar" }
      </Button>
     </DialogActions>
    </DialogContent>
   </Dialog>
  );
 }

 setClinicChanges(changes) {
  this.setState({
   clinicChanges: changes
  })
 }

 assignOrders() {
  const { clinicData } = this.props
  const { clinicChanges } = this.state

  const clinic = {
   ...clinicData,
   ...clinicChanges
  }

  let usersChanges = clinic.users ? [...clinic.users] : []


  let changes = {
   ...clinicChanges,
   users: this.withOrdersAssigned(usersChanges),
  }

  this.setClinicChanges(changes)
 }

 mergeUsersChanges(usersChangesDiff) {
  const { clinicData } = this.props
  const { clinicChanges } = this.state

  const clinic = {
   ...clinicData,
   ...clinicChanges
  }


  let usersChanges = clinic.users ? [...clinic.users] : []

  usersChangesDiff.forEach(userChange => {
   //const index = usersChanges.findIndex(user => user.id == userChange.id)
   const index =
    usersChanges.findIndex(user => (user.order == userChange.order) && (user.role == userChange.role))
   if (index >= 0) {
    usersChanges[index] = { ...userChange }
   }
   else {
    usersChanges.unshift(userChange)
   }
  })

  let changes = {
   ...clinicChanges,
   users: this.withOrdersAssigned(usersChanges),
  }

  this.setClinicChanges(changes)
 }

 excludeUser(user) {
  const { clinicData } = this.props
  const { clinicChanges } = this.state

  const clinic = {
   ...clinicData,
   ...clinicChanges
  }

  let usersChanges = clinic.users ? [...clinic.users]
   .filter(u => !((u.order == user.order) && (u.role == user.role))) : []

  let changes = {
   ...clinicChanges,
   users: this.withOrdersAssigned(usersChanges),
  }

  this.setClinicChanges(changes)
 }

 onSave() {
  if (this.validateForm()) {
   this.saveDialogRef.current.show()
  }
  else {
   this.validationDialogRef.current.show()
  }
 }

 onRestoreUsers() {
  this.restoreUsersDialogRef.current.show()
 }

 restoreUsers() {
  const { clinicData } = this.props
  const { clinicChanges } = this.state

  const users = clinicData.users

  let usersChanges = clinicData.users ? [...clinicData.users] : []

  let changes = {
   ...clinicChanges,
   users: this.withOrdersAssigned(usersChanges),
  }

  this.setClinicChanges(changes)
 }

 openUserEditForm(props) {
  this.setState({ openUserEdit: true, currentUser: props.row })
 }

 closeUserEditForm() {
  this.setState({ openUserEdit: false })
 }

 toggleUsersPanel(newPanel) {
  this.setState({
   panel: newPanel
  })
 }

 fieldError(field, value) {
  const { clinicData } = this.props
  const { clinicChanges } = this.state

  const clinic = {
   ...clinicData,
   ...clinicChanges
  }

  const usersError = (users) => {
   /* return users.every(user =>
    (!!user.name) && ((user.email && user.email.match(/\S+@\S+\.\S+/)))
   ) */
   const usersError = []

   if (!users || !users.some(user => user.role == "Administrator")) {
    usersError.push("Não há nenhum usuário administrador")
    return usersError
   }

   users.forEach(user => {
    let userError = []

    const name = user.name || ""
    const email = user.email || ""
    const password = user.password || ""
    const confirmPassword = user.confirmPassword || ""

    if (name.trim() == "")
     userError.push("Nome em branco")

    if (!(email.trim() == "") && !email.match(EMAIL_REGEX))
     userError.push("E-mail inválido")

    if (!user.id || password) {
     if (password.trim() == "")
      userError.push("Senha em branco")
     else if (!password.match(PASSWORD_REGEX))
      userError.push("Senha deve ter entre 6 e 128 caracteres")
     else if (password != confirmPassword)
      userError.push("'Senha' e 'Confirmar Senha' estão diferentes")
    }
  
    if (userError.length > 0)
     usersError.push(`Usuário ${user.role} (${user.order}): ` + userError.join(", "))
   })



   return (usersError.length > 0) ? usersError : false
  }

  const { users } = clinic

  switch (field) {
   case 'users': return usersError(value)
  }
 }

 validateForm() {
  const { clinicData } = this.props
  const { clinicChanges } = this.state

  const clinic = {
   ...clinicData,
   ...clinicChanges
  }

  const nextErrors = {}
  const fields = ['users']

  fields.forEach(field => {
   nextErrors[field] = this.fieldError(field, clinic[field])
  })

  console.log("*** ClinicUsersForm - validateForm: ", JSON.stringify(nextErrors))

  this.setErrors(nextErrors)

  for (var prop in nextErrors) {
   if (nextErrors[prop])
    return false
  }

  return true
 }

 setErrors(errors) {
  this.setState({
   errors: errors
  })
 }
}

export default ClinicUsersForm;