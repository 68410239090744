import React from "react"
import { Paper, Typography } from "@mui/material"


const About = (props) => (
 <Paper>
  <div style={ { height: 600, width: '100%' } }>


   <div style={ { display: 'flex', height: '100%' } }>
    <div style={ { flexGrow: 1 } }>
     <Typography
      variant="h6"
      style={ {
       whiteSpace: "nowrap",
       overflow: "hidden",
       padding: 10
      }}
     >
      { "Em construção... " }
     </Typography>
    </div>
   </div>
  </div>
 </Paper>
)

export default About