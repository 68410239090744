import React from 'react'
import {
 Dialog, DialogActions, DialogContent, DialogTitle, Button, Grid, TextField, MenuItem
} from "@mui/material"
import { Autocomplete } from '@mui/lab';
import InputNumber from '../../components/Inputs/InputNumber';
import { cpfMask } from '../../components/Masks/cpfMask';
import { specialities } from './tableProps';


class ProfessionalForm extends React.Component {
 constructor(props) {
  super(props)

  this.state = {
   professionalChanges: {},
   errors: {}
  }

  this.messages = {
   name: "O nome não pode ficar em branco",
   cpf: "CPF inválido",
   email: "E-mail inválido",
   council_number: "O número da entidade não pode ficar em branco"
  }

  this.councilTypes = [
   "CRM-RJ", "CRO-RJ", "CRP-RJ", "CRM-SP", "CRO-SP", "CRP-SP"
  ]

  this.validateForm = this.validateForm.bind(this)
  this.setProfessionalChanges = this.setProfessionalChanges.bind(this)
 }

 componentDidMount() {

 }

 componentDidUpdate(prevProps, prevState) {
  const { open } = this.props

  if (prevProps.open && !open) {
   this.setState({
    professionalChanges: {},
    errors: {}
   })
  }

  if (!prevProps.open && open) {
  }

 }


 render() {
  const { professionalData, open, onClose, onSave } = this.props
  const { professionalChanges, errors } = this.state

  const messages = this.messages
  const councilTypes = this.councilTypes

  const professional = {
   ...professionalData,
   ...professionalChanges
  }

  const { id, name, cpf, email, speciality, council_type, council_number, default_percentage } = professional

  const isNewProfessional = professionalData.id == undefined

  console.log("**** ProfessionalForm.render - Professional: ", JSON.stringify(professional))

  return (
   <Dialog aria-labelledby="simple-dialog-title"
    open={ open } onClose={ onClose } maxWidth='md' fullWidth>
    <DialogTitle id="simple-dialog-title">
     { isNewProfessional ? "Novo Profissional" : `Editar Profissional (${id})` }
    </DialogTitle>
    <DialogContent>
     <Grid container spacing={ 2 }>
      <Grid item md={ 12 }>
       <TextField
        required
        label="Nome"
        margin="dense"
        variant="outlined"
        error={ false || errors.name }
        helperText={ errors.name ? messages.name : "" }
        fullWidth
        value={ name || "" }
        onChange={ (event) => {
         const changes = {
          ...professionalChanges,
          name: event.target.value
         }
         this.setProfessionalChanges(changes)
        } }
       />
      </Grid>

      <Grid item md={ 5 }>
       <TextField
        required
        margin="dense"
        variant="outlined"
        label="E-mail"
        error={ false || errors.email }
        helperText={ errors.email ? "E-mail inválido" : "" }
        fullWidth
        value={ email || "" }
        onChange={ (event) => {
         const changes = {
          ...professionalChanges,
          email: event.target.value
         }
         this.setProfessionalChanges(changes)
        } }
       />
      </Grid>

      <Grid item md={ 3 }>
       <TextField
        required
        margin="dense"
        variant="outlined"
        label="CPF"
        error={ false || errors.cpf }
        helperText={ errors.cpf ? "CPF inválido" : "" }
        fullWidth
        value={ cpf || "" }
        InputProps={ { inputComponent: cpfMask } }
        InputLabelProps={ { shrink: true } }
        onChange={ (event) => {
         const changes = {
          ...professionalChanges,
          cpf: event.target.value
         }
         this.setProfessionalChanges(changes)
        } }
       />
      </Grid>

      <Grid item md={ 4 }>
       <Autocomplete
        freeSolo
        options={ specialities }
        value={ speciality || "" }
        onChange={ (event, value) => {
         const changes = {
          ...professionalChanges,
          speciality: value
         }
         this.setProfessionalChanges(changes)
        }
        }

        inputValue={ speciality || "" }
        onInputChange={ (event, value) => {
         const changes = {
          ...professionalChanges,
          speciality: value
         }
         this.setProfessionalChanges(changes)
        }
        }
        renderInput={ (params) =>
         <TextField
          { ...params }
          fullWidth
          margin="dense"
          label="Especialidade"
          variant="outlined"
         >
         </TextField> }
       />
      </Grid>

      <Grid item md={ 3 }>
       <Autocomplete
        freeSolo
        options={ councilTypes }
        value={ council_type || "" }
        onChange={ (event, value) => {
         const changes = {
          ...professionalChanges,
          council_type: value
         }
         this.setProfessionalChanges(changes)
        }
        }

        inputValue={ council_type || "" }
        onInputChange={ (event, value) => {
         const changes = {
          ...professionalChanges,
          council_type: value
         }
         this.setProfessionalChanges(changes)
        }
        }
        renderInput={ (params) =>
         <TextField
          { ...params }
          fullWidth
          margin="dense"
          label="Conselho regional"
          variant="outlined"
         >
         </TextField> }
       />
      </Grid>

      <Grid item md={ 6 }>
       <TextField
        required
        label="Número da entidade"
        margin="dense"
        variant="outlined"
        error={ false || errors.council_number }
        helperText={ errors.council_number ? messages.council_number : "" }
        fullWidth
        value={ council_number || "" }
        onChange={ (event) => {
         const changes = {
          ...professionalChanges,
          council_number: event.target.value
         }
         this.setProfessionalChanges(changes)
        } }
       />
      </Grid>
      <Grid item md={ 3 }>
       <InputNumber
        label='Recebimento padrão (%)'
        variant="outlined"
        margin='dense'
        minimum={ 0 }
        maximum={ 100 }
        step={ 1 }
        value={ default_percentage ? Math.round(default_percentage * 100) : 50 }
        onChange={ (value) => {
         const changes = {
          ...professionalChanges,
          default_percentage: value / 100
         }
         this.setProfessionalChanges(changes)
        } }
       />
      </Grid>

     </Grid>
    </DialogContent>
    <DialogActions>
     <Button onClick={ onClose } color="secondary">
      Cancelar
     </Button>
     <Button onClick={ (event) => {
      if (this.validateForm()) {
       onSave(professional)
      }
     } }
      color="primary">
      Salvar
     </Button>
    </DialogActions>
   </Dialog >
  )
 }

 setProfessionalChanges(changes) {
  this.setState({
   professionalChanges: changes
  })

 }

 validateForm() {

  function validateCPF(maskedCPF) {
   let cpfDigits = maskedCPF.replaceAll(/\D/g, "").split('')

   if (cpfDigits.length != 11)
    return false

   let sum = 0
   for (let i = 0, m = 10; i < cpfDigits.length - 2; ++i, --m)
    sum += m * cpfDigits[i]

   if (((sum * 10) % 11) % 10 != cpfDigits[9])
    return false

   sum = 0
   for (let i = 0, m = 11; i < cpfDigits.length - 1; ++i, --m)
    sum += m * cpfDigits[i]

   if (((sum * 10) % 11) % 10 != cpfDigits[10])
    return false

   return true
  }

  const { professionalData } = this.props
  const { professionalChanges } = this.state

  const professional = {
   ...professionalData,
   ...professionalChanges
  }

  const { name, email, cpf } = professional

  const nextErrors = {}

  nextErrors.name = !name
  nextErrors.email = !(email && email.match(/\S+@\S+\.\S+/))
  nextErrors.cpf = !(cpf && validateCPF(cpf))

  this.setState({
   errors: nextErrors
  })

  for (var prop in nextErrors) {
   if (nextErrors[prop])
    return false
  }

  return true
 }
}

export default ProfessionalForm