const initialState = {
 user_id: 0,
 email: '',
 name: ''
}

export const authReducer = (state = initialState, action) => {
 
 switch (action.type) {
  case 'LOGIN':
   return {
    ...state,
    user_id: localStorage.getItem('user_id'),
    name: localStorage.getItem('name'),
    email: localStorage.getItem('email')
   }
  case 'LOGOUT':
   return {
    ...state,
    user_id: null,
    name: null,
    email: null
   }
  default:
   return state;
 }
}

