import React from "react";
import { Typography } from "@mui/material";
import { Error, Info, Warning } from "@mui/icons-material";

/*
Componente auxiliar baseado em função a ser usado como possível valor da propriedade 'content' de LayoutDialog. Recebe um valor
para a propriedade 'type', que especifica o tipo de ícone apresentado (Aviso, Informação, Erro), e um valor
para a propriedade 'text', que especifica o texto exibido ao lado do ícone
*/

const LayoutDialogContent = (props) => (
 <>
  <div style={ { display: 'flex', flexDirection: 'row', gap: '1em', alignItems: 'center' } }>
   {
    (() => {
     switch (props.type) {
      case 'warning': return <Warning fontSize='large' />
      case 'info': return <Info fontSize='large' />
      case 'error': return <Error fontSize='large' />
     }
    })()
   }
   <Typography style={ { fontWeight: 'bold' } }>
    { props.text }
   </Typography>
  </div>

 </>
)

export default LayoutDialogContent