import * as React from 'react';
import { HashRouter, Switch, Route, Redirect, Link } from "react-router-dom";
import { createHashHistory } from 'history';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import DomainIcon from '@mui/icons-material/Domain';
import InfoIcon from '@mui/icons-material/Info';
import Clinics from './routes/Clinics/Clinics';
import { getHomeContent, getUserRole, isAuthenticated, logout } from './requests/auth';
import SignIn from './routes/Login/SignIn';
import { Menu, MenuItem } from '@mui/material';
import About from './routes/About';

const drawerWidth = 240;

function ListItemLink(props) {
 const { icon, primary, to } = props;

 const CustomLink = React.useMemo(
  () =>
   React.forwardRef((linkProps, ref) => (
    <Link ref={ ref } to={ to } { ...linkProps } />
   )),
  [to],
 );

 return (
  <li>
   <ListItem button component={ CustomLink }>
    <ListItemIcon>{ icon }</ListItemIcon>
    <ListItemText primary={ primary } />
   </ListItem>
  </li>
 );
}


const PublicRoute = ({ component: Component, componentProps, roles, ...rest }) => (
 <Route
  { ...rest }
  render={ props => {
   return <Component { ...props } { ...componentProps } />
  }

  }
 />
);

const PrivateRoute = ({ component: Component, componentProps, ...rest }) => (
 <Route
  { ...rest }
  render={ props => {
   if (!isAuthenticated())
    return <Redirect to={ { pathname: '/login', state: { from: props.location } } } />

   return <Component { ...props } { ...componentProps } />
  }

  }
 />
);

const openedMixin = (theme) => ({
 width: drawerWidth,
 transition: theme.transitions.create('width', {
  easing: theme.transitions.easing.sharp,
  duration: theme.transitions.duration.enteringScreen,
 }),
 overflowX: 'hidden',
});

const closedMixin = (theme) => ({
 transition: theme.transitions.create('width', {
  easing: theme.transitions.easing.sharp,
  duration: theme.transitions.duration.leavingScreen,
 }),
 overflowX: 'hidden',
 width: `calc(${theme.spacing(7)} + 1px)`,
 [theme.breakpoints.up('sm')]: {
  width: `calc(${theme.spacing(8)} + 1px)`,
 },
});

const DrawerHeader = styled('div')(({ theme }) => ({
 display: 'flex',
 alignItems: 'center',
 justifyContent: 'flex-end',
 padding: theme.spacing(0, 1),
 // necessary for content to be below app bar
 ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
 shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
 zIndex: theme.zIndex.drawer + 1,
 transition: theme.transitions.create(['width', 'margin'], {
  easing: theme.transitions.easing.sharp,
  duration: theme.transitions.duration.leavingScreen,
 }),
 ...(open && {
  marginLeft: drawerWidth,
  width: `calc(100% - ${drawerWidth}px)`,
  transition: theme.transitions.create(['width', 'margin'], {
   easing: theme.transitions.easing.sharp,
   duration: theme.transitions.duration.enteringScreen,
  }),
 }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
 ({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
   ...openedMixin(theme),
   '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
   ...closedMixin(theme),
   '& .MuiDrawer-paper': closedMixin(theme),
  }),
 }),
);

export const RoutedContent = () => {
 return (
  <Switch>

   {/*    Pages Routes    */ }

   <Redirect from="/" to={ getHomeContent(getUserRole()) } exact />
   <PrivateRoute component={ Clinics } path="/clinicas" />
   <PrivateRoute component={ About } path="/sobre" />



   {/* <Route exact path="/procedimentos">
       <Procedures />
      </Route>

      <Route exact path="/pacientes">
       <Patients />
      </Route>

      <Route exact path="/clinicas">
       <Clinics />
      </Route> */}

  </Switch>
 )
}

export const AppLayout = () => {
 const theme = useTheme();
 const history = createHashHistory()
 const [open, setOpen] = React.useState(true);
 const [anchorEl, setAnchorEl] = React.useState(null);


 const handleDrawerOpen = () => {
  setOpen(true);
 };

 const handleDrawerClose = () => {
  setOpen(false);
 };

 const handleMenu = (event) => {
  setAnchorEl(event.currentTarget);
 };

 const handleClose = () => {
  setAnchorEl(null);
 };

 const logoutAndClose = () => logout().then(() => {
  handleClose()
  history.push('/login')
 })

 return (
  <Switch>
   <PublicRoute component={ SignIn } path="/login" />
   <Box sx={ { display: 'flex' } }>
    <CssBaseline />
    <AppBar position="fixed" open={ open }>
     <Toolbar>
      <IconButton
       color="inherit"
       aria-label="open drawer"
       onClick={ handleDrawerOpen }
       edge="start"
       sx={ {
        marginRight: 5,
        ...(open && { display: 'none' }),
       } }
      >
       <MenuIcon />
      </IconButton>
      <Typography variant="h6" component="div" sx={ { flexGrow: 1 } }>
       Yudoc Admin
      </Typography>
      <IconButton
       size="large"
       aria-label="account of current user"
       aria-controls="menu-appbar"
       aria-haspopup="true"
       onClick={ handleMenu }
       color="inherit"
      >
       <AccountCircleIcon />
      </IconButton>
      <Menu
       id="menu-appbar"
       anchorEl={ anchorEl }
       anchorOrigin={ {
        vertical: 'top',
        horizontal: 'right',
       } }
       keepMounted
       transformOrigin={ {
        vertical: 'top',
        horizontal: 'right',
       } }
       open={ Boolean(anchorEl) }
       onClose={ handleClose }
      >
       <MenuItem onClick={ () => logoutAndClose() }>Logout</MenuItem>
      </Menu>
     </Toolbar>
    </AppBar>
    <Drawer variant="permanent" open={ open }>
     <DrawerHeader>
      <IconButton onClick={ handleDrawerClose }>
       { theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon /> }
      </IconButton>
     </DrawerHeader>
     <Divider />
     <List>
      <ListItemLink icon={ <DomainIcon /> } primary="Clínicas" to="/clinicas" />
      <ListItemLink icon={ <InfoIcon /> } primary="Sobre" to="/sobre" />

     </List>
     <Divider />

    </Drawer>
    <Box component="main" sx={ { flexGrow: 1, p: 3 } }>
     <DrawerHeader />
     <RoutedContent />
    </Box>

   </Box>
  </Switch>
 )
}

const basePath = process.env.BASE_PATH || '/';

export default function App() {

 return (
  <HashRouter basename={ basePath }>
   <AppLayout />
  </HashRouter>
 );
}
