import React from "react";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button'


export default class SimpleDialog extends React.Component {

 constructor(props) {
  super(props)

  this.state = {
   open: false
  }
  this.hide = this.hide.bind(this)
 }

 show(subject) {
  this.setState({ open: true, subject: subject })
 }

 hide() {
  this.setState({ open: false })
 }

 render() {

  const hide = this.hide
  const { open, subject } = this.state
  const { onConfirm, title, message, confirm_button, dismiss_button, ack_button } = this.props

  return (
   <Dialog
    open={ open }
    onClose={ hide }
    aria-labelledby="dialog-title"
    aria-describedby="dialog-description"
   >
    { title && <DialogTitle>{ title }</DialogTitle> }
    <DialogContent>
     <DialogContentText id="simple-dialog-message">
      { message }
     </DialogContentText>
    </DialogContent>
    <DialogActions>
     <Button onClick={ hide } color="primary" autoFocus>
      { dismiss_button }
     </Button>

     <Button onClick={ (event) => {
      hide();
      onConfirm(subject)
     } }
      color="primary" >
      { confirm_button }
     </Button>
    </DialogActions>
   </Dialog>
  )
 }
}