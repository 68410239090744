import React from "react"
import {
 Dialog, DialogActions, DialogContent, DialogTitle,
 Button, Grid, MenuItem, TextField
} from '@mui/material'
import { phoneMask } from "../../components/Masks/phoneMask"
import { cepMask } from "../../components/Masks/cepMask"
import { cnpjMask } from "../../components/Masks/cnpjMask"
import { cpfMask } from "../../components/Masks/cpfMask"

const NATURAL_PERSON = 1, LEGAL_ENTITY = 2


class ClinicForm extends React.Component {
 constructor(props) {
  super(props)

  this.brazilianStates = [
   { value: "AC", name: "Acre" },
   { value: "AL", name: "Alagoas" },
   { value: "AP", name: "Amapá" },
   { value: "AM", name: "Amazonas" },
   { value: "BA", name: "Bahia" },
   { value: "CE", name: "Ceará" },
   { value: "DF", name: "Distrito Federal" },
   { value: "ES", name: "Espírito Santo" },
   { value: "GO", name: "Goiás" },
   { value: "MA", name: "Maranhão" },
   { value: "MT", name: "Mato Grosso" },
   { value: "MS", name: "Mato Grosso Do Sul" },
   { value: "MG", name: "Minas Gerais" },
   { value: "PA", name: "Pará" },
   { value: "PB", name: "Paraíba" },
   { value: "PR", name: "Paraná" },
   { value: "PE", name: "Pernambuco" },
   { value: "PI", name: "Piauí" },
   { value: "RJ", name: "Rio de Janeiro" },
   { value: "RN", name: "Rio Grande do Norte" },
   { value: "RS", name: "Rio Grande do Sul" },
   { value: "RO", name: "Rondônia" },
   { value: "RR", name: "Roraima" },
   { value: "SC", name: "Santa Catarina" },
   { value: "SP", name: "São Paulo" },
   { value: "SE", name: "Sergipe" },
   { value: "TO", name: "Tocantins" }
  ]

  this.clinicTypes = [
   {
    id: NATURAL_PERSON,
    name: "Pessoa Física"
   },
   {
    id: LEGAL_ENTITY,
    name: "Pessoa Jurídica"
   }
  ]

  this.messages = {
   name: "O nome não pode ficar em branco",
   email: "E-mail inválido",
   cpf: "CPF inválido",
   cnpj: "CNPJ inválido"
  }

  this.state = {
   clinicChanges: {},
   clinicType: this.clinicTypes.find(type => type.id == LEGAL_ENTITY),
   errors: {}
  }

  this.setClinicChanges = this.setClinicChanges.bind(this)
  this.setClinicType = this.setClinicType.bind(this)
  this.setErrors = this.setErrors.bind(this)
  this.validate = this.validate.bind(this)
  this.validateForm = this.validateForm.bind(this)
 }


 componentDidMount() {

 }

 componentDidUpdate(prevProps, prevState) {
  const { open, clinicData } = this.props
  const { clinicChanges } = this.state

  const clinic = {
   ...clinicData,
   ...clinicChanges
  }

  const { cpf, cnpj } = clinic

  if (prevProps.open && !open) {
   this.setState({
    clinicChanges: {},
    clinicType: this.clinicTypes.find(type => type.id == LEGAL_ENTITY),
    errors: {}
   })
  }

  if (!prevProps.open && open) {
   if (cpf && !cnpj) {
    this.setState({
     clinicType: this.clinicTypes.find(type => type.id == NATURAL_PERSON)
    })
   }
   else if (!cpf && cnpj) {
    this.setState({
     clinicType: this.clinicTypes.find(type => type.id == LEGAL_ENTITY)
    })
   }
  }
 }

 render() {
  const { open, onClose, onCommit, clinicData } = this.props
  const { clinicChanges, clinicType, errors } = this.state


  const clinic = {
   ...clinicData,
   ...clinicChanges
  }

  const { id, name, email, cpf, cnpj, phone, cellphone, address } = clinic

  const postalCode = address ? address.postal_code : null
  const addrName = address ? address.name : null
  const number = address ? address.number : null
  const complement = address ? address.complement : null
  const neighborhood = address ? address.neighborhood : null
  const city = address ? address.city : null
  const state = address ? address.state : null

  const origUsers = clinicData ? clinicData.users : []


  const isNewClinic = (clinicData == null) || (clinicData.id == undefined)

  const messages = this.messages
  const brazilianStates = this.brazilianStates
  const clinicTypes = this.clinicTypes

  return (
   <Dialog aria-labelledby="simple-dialog-title"
    open={ open } onClose={ onClose } maxWidth='md' fullWidth>
    <DialogTitle id="simple-dialog-title">
     { clinic.id ? `Editando clínica "${clinic.name}"` : `Nova clínica` }
    </DialogTitle>
    <DialogContent>
     <Grid container spacing={ 2 } justifyContent="center" alignItems="center">
      <Grid item md={ 7 }>
       <TextField

        label="Nome"
        margin="dense"
        size="small"
        variant="outlined"
        error={ false || errors.name }
        helperText={ errors.name ? messages.name : "" }
        fullWidth
        value={ name || "" }
        onChange={ (event) => {
         const changes = {
          ...clinicChanges,
          name: event.target.value
         }
         const errorsUpdate = {
          ...errors,
          name: !this.validate('name', event.target.value)
         }
         this.setClinicChanges(changes)
         this.setErrors(errorsUpdate)
        } }
       />
      </Grid>

      <Grid item md={ 2 }>
       <TextField
        select
        label="Tipo"
        margin="dense"
        size="small"
        variant="outlined"
        fullWidth
        value={ clinicType.name || "" }
        onChange={ (event) => {
         let typeName = event.target.value
         this.setClinicType(typeName)
        } }
       >
        { clinicTypes.map((type) => (
         <MenuItem key={ type.id } value={ type.name }>
          { type.name }
         </MenuItem>
        )) }
       </TextField>
      </Grid>

      {/* ------ FORM SECOND LINE ------ */ }

      { clinicType && clinicType.id == LEGAL_ENTITY &&

       <Grid item md={ 3 }>
        <TextField
         fullWidth
         label="CNPJ"
         margin="dense"
         size="small"
         variant="outlined"
         error={ false || errors.cnpj }
         helperText={ errors.cnpj ? messages.cnpj : "" }
         InputProps={ { inputComponent: cnpjMask } }
         InputLabelProps={ { shrink: true } }
         value={ cnpj || "" }
         onChange={ (event) => {
          const changes = {
           ...clinicChanges,
           cnpj: event.target.value
          }
          this.setClinicChanges(changes)
         } }

        />
       </Grid>
      }


      { clinicType && clinicType.id == NATURAL_PERSON &&

       <Grid item md={ 3 }>
        <TextField
         fullWidth
         label="CPF"
         margin="dense"
         size="small"
         variant="outlined"
         error={ false || errors.cpf }
         helperText={ errors.cpf ? messages.cpf : "" }
         InputProps={ { inputComponent: cpfMask } }
         InputLabelProps={ { shrink: true } }
         value={ cpf || "" }
         onChange={ (event) => {
          const changes = {
           ...clinicChanges,
           cpf: event.target.value
          }
          this.setClinicChanges(changes)
         } }
        />
       </Grid>
      }

      <Grid item md={ 6 }>
       <TextField

        margin="dense"
        size="small"
        variant="outlined"
        label="E-mail"
        error={ false || errors.email }
        helperText={ errors.email ? messages.email : "" }
        fullWidth
        value={ email || "" }
        onChange={ (event) => {
         const changes = {
          ...clinicChanges,
          email: event.target.value
         }
         const errorsUpdate = {
          ...errors,
          email: !this.validate('email', event.target.value)
         }
         this.setClinicChanges(changes)
         this.setErrors(errorsUpdate)
        } }
       />
      </Grid>

      <Grid item md={ 3 }>
       <TextField
        margin="dense"
        size="small"
        variant="outlined"
        label="Telefone"
        fullWidth
        value={ phone || "" }
        InputProps={ { inputComponent: phoneMask } }
        InputLabelProps={ { shrink: true } }
        onChange={ (event) => {
         const changes = {
          ...clinicChanges,
          phone: event.target.value
         }
         this.setClinicChanges(changes)
        } }
       />
      </Grid>


      <Grid item md={ 3 }>
       <TextField
        margin="dense"
        size="small"
        variant="outlined"
        label="Tel. celular"
        fullWidth
        value={ cellphone || "" }
        InputProps={ { inputComponent: phoneMask } }
        InputLabelProps={ { shrink: true } }
        onChange={ (event) => {
         const changes = {
          ...clinicChanges,
          cellphone: event.target.value
         }
         this.setClinicChanges(changes)
        } }
       />
      </Grid>

      {/* ------ FORM THIRD LINE ------ */ }
      <Grid item md={ 2 }>
       <TextField

        margin="dense"
        size="small"
        variant="outlined"
        label="CEP"
        fullWidth
        value={ postalCode || "" }
        InputProps={ { inputComponent: cepMask } }
        InputLabelProps={ { shrink: true } }
        onChange={ (event) => {
         const changes = {
          ...clinicChanges,
          address: {
           ...clinic.address,
           postal_code: event.target.value
          }
         }
         this.setClinicChanges(changes)
        } }
       />
      </Grid>
      <Grid item md={ 6 }>
       <TextField

        label="Endereço"
        margin="dense"
        size="small"
        variant="outlined"
        error={ false || errors.addrName }
        helperText={ errors.addrName ? messages.addrName : "" }
        fullWidth
        value={ addrName || "" }
        onChange={ (event) => {
         const changes = {
          ...clinicChanges,
          address: {
           ...clinic.address,
           name: event.target.value
          }
         }
         this.setClinicChanges(changes)
        } }
       />
      </Grid>
      <Grid item md={ 2 }>
       <TextField
        label="Número"
        margin="dense"
        size="small"
        variant="outlined"
        fullWidth
        value={ number || "" }
        onChange={ (event) => {
         const changes = {
          ...clinicChanges,
          address: {
           ...clinic.address,
           number: event.target.value
          }
         }
         this.setClinicChanges(changes)
        } }
       />
      </Grid>
      <Grid item md={ 2 }>
       <TextField

        label="Comp."
        margin="dense"
        size="small"
        variant="outlined"
        fullWidth
        value={ complement || "" }
        onChange={ (event) => {
         const changes = {
          ...clinicChanges,
          address: {
           ...clinic.address,
           complement: event.target.value
          }
         }
         this.setClinicChanges(changes)
        } }
       />
      </Grid>

      <Grid item md={ 6 }>
       <TextField

        label="Cidade"
        margin="dense"
        size="small"
        variant="outlined"
        error={ false || errors.city }
        helperText={ errors.city ? messages.city : "" }
        fullWidth
        value={ city || "" }
        onChange={ (event) => {
         const changes = {
          ...clinicChanges,
          address: {
           ...clinic.address,
           city: event.target.value
          }
         }
         this.setClinicChanges(changes)
        } }
       />
      </Grid>

      <Grid item md={ 3 }>
       <TextField

        label="Bairro/distrito"
        margin="dense"
        size="small"
        variant="outlined"
        error={ false || errors.neighborhood }
        helperText={ errors.neighborhood ? messages.neighborhood : "" }
        fullWidth
        value={ neighborhood || "" }
        onChange={ (event) => {
         const changes = {
          ...clinicChanges,
          address: {
           ...clinic.address,
           neighborhood: event.target.value
          }
         }
         this.setClinicChanges(changes)
        } }
       />
      </Grid>

      {/* ------ FORM 4th LINE ------ */ }

      <Grid item md={ 3 }>
       <TextField
        select
        fullWidth
        label="Estado"
        variant="outlined"
        margin="dense"
        size="small"
        value={ state }
        onChange={ (event) => {
         const changes = {
          ...clinicChanges,
          address: {
           ...clinic.address,
           state: event.target.value
          }
         }
         this.setClinicChanges(changes)
        } }
       >
        {
         brazilianStates.map((state) => (
          <MenuItem key={ state.value } value={ state.value }>
           { state.name }
          </MenuItem>
         )) }
       </TextField>
      </Grid>

     </Grid>

     <DialogActions>
      <Button onClick={ onClose } color="secondary">
       Cancelar
      </Button>
      <Button onClick={ (event) => {
       {
        if (this.validateForm()) {
         onCommit(clinic, origUsers, isNewClinic ? this.constructor.name : null)
        }
       }
      } }
       color="primary">
       { isNewClinic ? "Próximo" : "Salvar" }
      </Button>
     </DialogActions>
    </DialogContent>
   </Dialog>
  )
 }

 setClinicType(typeName) {
  const { clinicChanges } = this.state
  const clinicTypes = this.clinicTypes

  const clinicType = clinicTypes.find(clinicType => clinicType.name == typeName)

  let changes = {
   ...clinicChanges,
   [(clinicType.id == LEGAL_ENTITY) ? "cpf" : "cnpj"]: null
  }

  this.setState({
   clinicType: clinicType,
   clinicChanges: changes
  })
 }

 setClinicChanges(changes) {
  this.setState({
   clinicChanges: changes
  })
 }

 setErrors(errors) {
  this.setState({
   errors: errors
  })
 }

 validate(field, value) {

  const validateCPF = (maskedCPF) => {
   let cpfDigits = maskedCPF.replaceAll(/\D/g, "").split('')

   if (cpfDigits.length != 11)
    return false

   let sum = 0
   for (let i = 0, m = 10; i < cpfDigits.length - 2; ++i, --m)
    sum += m * cpfDigits[i]

   if (((sum * 10) % 11) % 10 != cpfDigits[9])
    return false

   sum = 0
   for (let i = 0, m = 11; i < cpfDigits.length - 1; ++i, --m)
    sum += m * cpfDigits[i]

   if (((sum * 10) % 11) % 10 != cpfDigits[10])
    return false

   return true
  }


  switch (field) {
   case 'name': return !!value
   case 'email': return (!value || value.match(/\S+@\S+\.\S+/))
   case 'cpf': return (!value || validateCPF(value))
   case 'cnpj': return true
  }
 }

 validateForm() {
  const { clinicData } = this.props
  const { clinicChanges } = this.state

  const clinic = {
   ...clinicData,
   ...clinicChanges
  }

  const nextErrors = {}
  const fields = ['name', 'email', 'cpf', 'cnpj']

  fields.forEach(field => {
   nextErrors[field] = !this.validate(field, clinic[field])
  })

  this.setErrors(nextErrors)

  for (var prop in nextErrors) {
   if (nextErrors[prop])
    return false
  }

  return true
 }

}

export default ClinicForm