import React from "react";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

/* Diálogo implementado sobre o Dialog do Material-UI que permite a especificação dos layouts de contéudo
(sob DialogContent) e de ações (sob DialogActions) a partir das propriedades 'content' e 'actions',
respectivamente. Os módulos LayoutDialogContent e LayoutDialogActions implementam possíveis componentes
para uso com essas propriedades.
A visibilidade é controlada a partir da variável interna de estado 'open'  */

export default class LayoutDialog extends React.Component {

 constructor(props) {
  super(props)

  this.state = {
   open: false
  }
  this.hide = this.hide.bind(this)
 }

 show(target) {
  this.setState({ open: true, target: target })
 }

 hide() {
  this.setState({ open: false })
 }

 render() {

  const hide = this.hide
  const { open, target } = this.state
  const { onConfirm, title, confirm_button, dismiss_button, ack_button,
   content, contentProps, actions, actionsProps } = this.props
  const ContentComponent = content
  const ActionsComponent = actions

  return (
   <Dialog
    open={ open }
    onClose={ hide }
    aria-labelledby="dialog-title"
    aria-describedby="dialog-description"
   >
    { title && <DialogTitle>{ title }</DialogTitle> }
    <DialogContent>
     <ContentComponent { ...contentProps } />
    </DialogContent>
    <DialogActions>
     <ActionsComponent { ...actionsProps } hide={ this.hide } target={ target } />
    </DialogActions>
   </Dialog>
  )
 }
}