import React from 'react';
import { TextField, IconButton, Icon } from '@mui/material';
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid'

import UsersGridToolbar from './UsersGridToolbar';

const useMutation = () => {
 return (
  (user) =>
   new Promise((resolve, reject) =>
    setTimeout(() => {
     resolve({
      ...user,
      name: user.name,
      email: user.email,
      password: user.password
     });
    }, 200),
   )
 )
};




class UsersGrid extends React.Component {
 constructor(props) {
  super(props)

  this.columns = [
   {
    field: 'id',
    headerName: 'Id',
    renderCell: params => (
     <strong>
      { params.value }
     </strong>
    )
   },
   {
    field: 'order',
    headerName: '#',
    renderCell: params => (
     <strong>
      { params.value }
     </strong>
    )
   },
   {
    field: 'name',
    headerName: 'Nome',
    sortable: false,
    editable: true,
    flex: 0.5
   },
   {
    field: 'email',
    headerName: 'E-mail',
    sortable: false,
    editable: true,
    flex: 0.3
   },
   {
    field: 'cpf',
    headerName: 'CPF',
    sortable: false,
    editable: true,
    flex: 0.2
   },
   {
    field: 'password',
    headerName: 'Senha',
    sortable: false,
    editable: true,
    flex: 0.2
   },
   {
    field: 'confirmPassword',
    headerName: 'Confirmar Senha',
    sortable: false,
    editable: true,
    flex: 0.2
   },
   {
    field: 'delete',
    headerName: 'Excluir',
    sortable: false,
    renderCell: (params) => (
     <IconButton onClick={ () => props.onExclude(params.row) }>
      <Icon>delete</Icon>
     </IconButton>
    ),
    width: 50
   }
   /* {
    field: 'actions',
    type: 'actions',
    width: 80,
    getActions: (params) => [
     <GridActionsCellItem icon={ <DeleteIcon fontSize='large' /> } onClick={ () => alert("Delete") } label="Delete" />,
     <GridActionsCellItem icon={ <EditIcon fontSize='large' /> } onClick={ () => alert("Edit") } label="Edit" showInMenu />
    ]
   } */

  ]

  if (props.role == "Professional") {
   this.columns.push({
    field: 'edit',
    headerName: 'Editar',
    sortable: false,
    renderCell: (params) => (
     <IconButton onClick={ () => { props.onEdit(params) } }>
      <Icon>edit</Icon>
     </IconButton>
    ),
    width: 50
   })
  }

  this.setCellModesModel = this.setCellModesModel.bind(this)
  this.setUsersChanges = this.setUsersChanges.bind(this)

  this.state = {
   cellModesModel: {},
   usersChanges: []
  }
 }

 /* shouldComponentUpdate() {
  return false
 } */

 render() {

  const { users, role, onChange, onRestore, onEdit, onExclude } = this.props
  const { editRowsModel, cellModesModel, usersChanges } = this.state
  const columns = this.columns

  const mutateRow = useMutation();


  console.log("*** UsersGrid.render - usersChanges: ", JSON.stringify(usersChanges))

  return (

   <div style={ { display: 'flex', height: 300 } }>
    <div style={ { flexGrow: 1 } }>
     <DataGrid
      rows={ users || [] }
      columns={ columns }
      processRowUpdate={ async (newRow, oldRow) => {
       return mutateRow(newRow).then(response => {

        onChange([newRow])


        return response
       }).catch();

       /*        onChange([newRow])
        */
      } }
      experimentalFeatures={ { newEditingApi: true } }
      initialState={ {
       columns: {
        columnVisibilityModel: {
         id: false
        },
       },
      } }

      getRowId={ row => row.order }

      components={ { Toolbar: UsersGridToolbar } }
      componentsProps={ {
       toolbar: {
        onChange: onChange,
        onRestore: onRestore,
        role: role
       }
      } }

      hideFooter
      disableColumnMenu
     />
    </div>
   </div>
  )
 }

 setCellModesModel(model) {
  this.setState({
   cellModesModel: model
  })
 }

 setUsersChanges(changes) {
  this.setState({
   usersChanges: changes
  })
 }



}

export default UsersGrid;

