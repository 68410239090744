/* eslint-disable react/display-name */
/* import React from 'react'
import { deleteRequest, patchRequest, postRequest } from '../Authentication/Api/requests'
import {
  successfulDelete,
  successfulCreation,
  failCreation,
  failDelete,
  successfulUpdate,
  failUpdate
} from '../Authentication/Api/toastForResponses'
import { toast } from 'react-toastify'
import { TextField } from '@mui/material'
import { cpfMask } from '../components/Masks/cpfMask'
import { useSelector } from 'react-redux'

export const columns = [
  { title: 'ID', field: 'id', hidden: true, align: 'center'},
  { title: 'Nome', field: 'name', align: 'center'},
  {
    title: 'CPF', field: 'cpf', align: 'center',
    editComponent: ({ value, onChange }) => (
      <TextField
        value={value}
        onChange={(e) => onChange(e.target.value)}
        InputProps={{
          inputComponent: cpfMask
        }}
      />
    )
  },
  { title: 'Email', field: 'email', align: 'center'},
  { title: 'Recebimento (%)', field: 'default_percentage', type: 'numeric', align: 'center'}
]

export function editableTable(professionals, setProfessionals) {
  const PERCENTAGE_PARSER = 100
  const clinic_id = useSelector(store => store.userReducer.clinic_id)
  return {
    onRowAdd: newProfessional =>
      new Promise((resolve, reject) => {
        setTimeout(() => {
          const data = {
            professional: {
              cpf: newProfessional.cpf,
              name: newProfessional.name,
              email: newProfessional.email,
              default_percentage: newProfessional.default_percentage / PERCENTAGE_PARSER,
              clinic_id: clinic_id,
              password: "clinica"
            }
          }
          postRequest('professionals', data)
            .then((response) => {
              setProfessionals([
                { id: response.data.id, ...newProfessional },
                ...professionals
              ])
              toast.success(successfulCreation('Profissional'))
              resolve()
            })
            .catch(err => {
              toast.error(failCreation(err.response.data.errors.full_messages))
              reject()
            })

        }, 1000)
      }),
    onRowUpdate: (newProfessional, oldProfessional) =>
      new Promise((resolve, reject) => {
        setTimeout(() => {
          const professionalUpdate = [...professionals];
          const index = oldProfessional.tableData.id;
          const data = {
            professional: {
              name: newProfessional.name,
              cpf: newProfessional.cpf,
              email: newProfessional.email,
              default_percentage: newProfessional.default_percentage / PERCENTAGE_PARSER
            }
          }
          patchRequest('professionals', data, `id=${oldProfessional.id}`)
            .then(() => {
              professionalUpdate[index] = newProfessional;
              setProfessionals([...professionalUpdate]);
              toast.success(successfulUpdate('Profissional'))
              resolve()
            })
            .catch(err => {
              toast.error(failUpdate(err.response.data.errors.full_messages))
              reject()
            })
        }, 1000)
      }),
    onRowDelete: oldProfessional =>
      new Promise((resolve, reject) => {
        setTimeout(() => {
          const professionalDelete = [...professionals];
          const index = oldProfessional.tableData.id;
          deleteRequest('professionals', `id=${oldProfessional.id}`)
            .then(
              () => {
                professionalDelete.splice(index, 1)
                setProfessionals([...professionalDelete])
                toast.success(successfulDelete('Profissional'))
                resolve()
              }
            )
            .catch(() => {
              toast.error(failDelete('Profissional'))
              reject()
            })
        }, 1000);
      }),
  }
} */

import { Icon, InputAdornment, TextField, MenuItem, Input } from "@mui/material"
import { Autocomplete } from '@mui/lab';

import React from "react"

export const specialities = [
 "Acupuntura", "Alergia e imunologia", "Anestesiologia", "Angiologia", "Cardiologia",
 "Cirurgia cardiovascular", "Cirurgia da mão", "Cirurgia de cabeça e pescoço",
 "Cirurgia do aparelho digestivo", "Cirurgia geral", "Cirurgia oncológica", "Cirurgia pediátrica",
 "Cirurgia plástica", "Cirurgia torácica", "Cirurgia vascular", "Clínica médica", "Coloproctologia",
 "Dermatologia", "Endocrinologia e metabologia", "Endoscopia", "Gastroenterologia", "Genética médica",
 "Geriatria", "Ginecologia e obstetrícia", "Hematologia e hemoterapia", "Homeopatia", "Infectologia",
 "Mastologia", "Medicina de emergência", "Medicina de família e comunidade", "Medicina do trabalho",
 "Medicina de tráfego", "Medicina esportiva", "Medicina física e reabilitação", "Medicina intensiva",
 "Medicina legal e perícia médica", "Medicina nuclear", "Medicina preventiva e social", "Nefrologia",
 "Neurocirurgia", "Neurologia", "Nutrologia", "Oftalmologia", "Oncologia clínica",
 "Ortopedia e traumatologia", "Otorrinolaringologia", "Patologia",
 "Patologia clínica/medicina laboratorial", "Pediatria", "Pneumologia", "Psicologia", "Psiquiatria",
 "Radiologia e diagnóstico por imagem", "Radioterapia", "Reumatologia", "Urologia"
]

export const columns = [
 {
  headerName: 'Id',
  field: 'id',
  hide: true
 },
 {
  headerName: 'Nome',
  field: 'name',
  flex: 0.3
 },
 {
  headerName: 'CPF',
  field: 'cpf',
  flex: 0.2
 },
 {
  headerName: 'E-mail',
  field: 'email',
  flex: 0.3
 },
 {
  headerName: 'Especialidade',
  field: 'speciality',
  flex: 0.2
 },
 {
  headerName: 'Registro profissional',
  field: 'council_type_number',
  flex: 0.2,
  valueGetter: (params) => params.row.council_type + " " + params.row.council_number
 }
]

export const options = {
 filterDefs: [
  {
   id: "byProfessionalName",
   column: "name",
   expression: (dataValue, filterValue) => dataValue ?
    (filterValue ? dataValue.toLocaleLowerCase().includes(filterValue.toLocaleLowerCase()) : false) : false,
   render: (getFilterValue, setFilterValue) =>
    <TextField
     type="search"
     value={ getFilterValue("byProfessionalName") }
     inputProps={ {
      style: { height: '2em' },
      placeholder: "Pesquisar pelo nome"
     } }
     InputLabelProps={ { shrink: true } }
     InputProps={ {
      style: { marginRight: '1em' },
      startAdornment: (
       <InputAdornment position="start">
        <Icon>search</Icon>
       </InputAdornment>
      ),
     } }
     onChange={ (event) => setFilterValue("byProfessionalName", event.target.value) } />
  },
  {
   id: "byProfessionalSpeciality",
   column: "speciality",
   expression: (dataValue, filterValue) => dataValue ?
    (filterValue ? dataValue.toLocaleLowerCase().includes(filterValue.toLocaleLowerCase()) : false) : false,
   render: (getFilterValue, setFilterValue) =>
    <Autocomplete
     freeSolo
     size='medium'
     options={ specialities }
     value={ getFilterValue("byProfessionalSpeciality") || "" }
     onChange={
      (event, value) => setFilterValue("byProfessionalSpeciality", value)
     }
     inputValue={ getFilterValue("byProfessionalSpeciality") || "" }
     onInputChange={
      (event, value) => setFilterValue("byProfessionalSpeciality", value)
     }


     renderInput={ (params) => {
      //console.log("%%% - ", JSON.stringify(params))
      return (
       <TextField
        { ...params }
        inputProps={ {
         ...params.inputProps,
         style: { height: '2em', width: '15em' },
         placeholder: "Pesquisar pela especialidade"
        } }
        InputProps={ {
         ...params.InputProps,
         startAdornment: (
          <InputAdornment position="start">
           <Icon>search</Icon>
          </InputAdornment>
         ),
        } }
       >
       </TextField>)
     } }
    />

  }
 ]

}

{/* <TextField
     select
     value={ getFilterValue("byProfessionalSpeciality") }
     defaultValue="Pesquisar pela especialidade"
     inputProps={ {
      style: { height: '2em' },
     } }
     InputProps={ {
      style: { width: '15em' },
      placeholder: "Pesquisar pela especialidade"
     } }
     onChange={ (event) => setFilterValue("byProfessionalSpeciality", event.target.value) }
     
    >
     {
      specialities && specialities.map(speciality =>
       <MenuItem key={ speciality.id } value={ speciality.id }>{ speciality.name }</MenuItem>)

     }
    </TextField> */}

