import { format, parseISO } from "date-fns"
import { dateX } from "../../components/Utils/dateUtils"

function getUsersDescription(params) {
 const administrators = params.row.users.filter(user => user.role == "Administrator")
 const secretaries = params.row.users.filter(user => user.role == "Secretary")
 const professionals = params.row.users.filter(user => user.role == "Professional")

 const admWord = (administrators.length > 1) ? "Administradores" : "Administrador"
 const secWord = (secretaries.length > 1) ? "Secretários" : "Secretário"
 const profWord = (professionals.length > 1) ? "Profissionais" : "Profissional"

 const usersSpec = []
 if (administrators.length > 0)
  usersSpec.push(`${administrators.length} ${admWord}`)

 if (secretaries.length > 0)
  usersSpec.push(`${secretaries.length} ${secWord}`)

 if (professionals.length > 0)
  usersSpec.push(`${professionals.length} ${profWord}`)

 return usersSpec.join(', ')
}

export const columns = [
 {
  headerName: 'Id',
  field: 'id',
  flex: 0.2
 },
 {
  headerName: 'Nome',
  field: 'name',
  hide: false,
  flex: 0.5
 },
 {
  headerName: 'Cadastrada em',
  field: 'created_at',
  hide: false,
  valueGetter: (params) => params.row.created_at,
  valueFormatter: params => format(parseISO(params.value), 'dd/MM/yyyy HH:mm'),
  flex: 0.3
 },
 {
  headerName: 'Usuários',
  field: 'users_description',
  valueGetter: getUsersDescription,
  flex: 0.5
 }
]