import React from "react"
import { Children } from "react"
import {
 Button, Dialog, DialogActions, DialogContent, DialogTitle,
 Grid, Paper, TextField, Typography, Chip, FormControl, FormGroup, FormLabel, FormControlLabel,
 RadioGroup, Radio, Checkbox, InputLabel, Input, FormHelperText
} from "@mui/material"
import { withStyles } from "@mui/styles"

import {
 KeyboardTimePicker,
 MuiPickersUtilsProvider
} from "@mui/x-date-pickers"
import { format, formatISO, parse, parseISO } from "date-fns"
import { settingStyles } from "./settingStyles"
import { CustomField, InputNumber } from "../../../components/Inputs"
import { weekDays } from "../../../utils/utils"
import { HOURS_MINUTES_FORMAT, ISO_FORMAT, dateX } from "../../../components/Utils/dateUtils"

function WeekChips(props) {
 let days = new Set(props.days)
 return (

  <div className={ props.wrapperClass }>
   {
    weekDays.map((weekDay, dayIndex) =>
     <Chip
      color={ props.days ? (props.days.has(dayIndex) ? "primary" : "default")
       : "default" }
      size="small"
      label={ weekDay }
      onClick={ () => {

       if (props.days) {
        if (props.days.has(dayIndex)) {
         days.delete(dayIndex)
        }
        else {
         days.add(dayIndex)
        }
       }
       else {
        days = new Set().add(dayIndex)
       }

       props.onChangeDays(days)
      } }
     />
    )
   }
  </div>
 )
}

const Section = (props) => {
 return (
  <Paper variant="outlined" square>
   <Typography style={ { fontWeight: 'bold' } }>{ props.title }</Typography>
   <div style={ {
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'stretch',
    gap: '10px'
   } }>


    {
     Children.map(props.children, child =>
      <div style={ {
       display: 'flex', alignItems: 'center',
       flexGrow: 1,
       //backgroundColor: '#' + Math.floor(Math.random() * 16777215).toString(16)
      } }>
       { child }
      </div>
     ) }
   </div>
  </Paper >
 )
}

class Settings extends React.Component {
 constructor(props) {
  super(props)

  this.state = {
   workWeekDays: new Set([1, 2, 3, 4, 5]),
   clinicChanges: {}
  }

  this.setWorkWeekDays = this.setWorkWeekDays.bind(this)
  this.createClinicSetting = this.createClinicSetting.bind(this)
  this.createClinicSettingAndRefreshUI = this.createClinicSettingAndRefreshUI.bind(this)
  this.setClinicChanges = this.setClinicChanges.bind(this)
  this.setClinicSettingsChanges = this.setClinicSettingChanges.bind(this)
  this.setAllowRevenueDeferredPayment = this.setAllowRevenueDeferredPayment.bind(this)
  this.onSaveTest = this.onSaveTest.bind(this)
 }

 componentDidMount() {

 }

 componentDidUpdate(prevProps, prevState) {
  const { open, clinicData } = this.props

  const settings = clinicData ? clinicData.settings : null
  const clinicSetting = settings ? settings.find(setting => setting.professional_id == null) : null

  if (prevProps.open && !open) {
   this.setState({
    workWeekDays: new Set([1, 2, 3, 4, 5]),
    clinicChanges: {}
   })
  }

  if ((!prevProps.open && open)) {
   if (!clinicSetting)
    this.createClinicSettingAndRefreshUI()
  }
 }


 render() {
  const { open, onClose, classes, clinicData, onCommit } = this.props
  const { workWeekDays } = this.state
  const { clinicChanges } = this.state

  const clinic = {
   ...clinicData,
   ...clinicChanges
  }

  const isNewClinic = (clinicData == null) || (clinicData.id == undefined)

  const settings = clinic.settings
  const clinicSetting = settings ? settings.find(setting => setting.professional_id == null) : null
  //const opening_time = clinicSetting ? dateX(clinicSetting.opening_time, ISO_FORMAT, HOURS_MINUTES_FORMAT) : ""
  const opening_time = clinicSetting ? format(parseISO(clinicSetting.opening_time), HOURS_MINUTES_FORMAT) : ""

  //const closing_time = clinicSetting ? dateX(clinicSetting.closing_time, ISO_FORMAT, HOURS_MINUTES_FORMAT) : ""
  const closing_time = clinicSetting ? format(parseISO(clinicSetting.closing_time), HOURS_MINUTES_FORMAT) : ""

  const work_week_days = clinicSetting ?
   new Set(clinicSetting.work_week_days.split(',').map(day => parseInt(day))) : new Set([1, 2, 3, 4, 5])

  const appt_revenue_allow_deferred_payment = clinicSetting ?
   clinicSetting.appt_revenue_allow_deferred_payment : false
  const appt_revenue_max_parcels = clinicSetting ? clinicSetting.appt_revenue_max_parcels : 1
  const appt_only_on_clinic_opening_days_times = clinicSetting ?
   clinicSetting.appt_only_on_clinic_opening_days_times : false


  const origUsers = clinicData ? clinicData.users : []

  console.log("*** Settings.render - clinic: ", JSON.stringify(clinic))
  console.log("*** Settings.render - clinicSetting: ", JSON.stringify(clinicSetting))


  return (
   <Dialog aria-labelledby="simple-dialog-title"
    open={ open } onClose={ onClose } maxWidth='sm' fullWidth>
    <DialogTitle id="simple-dialog-title">
     Configurações da clínica
    </DialogTitle>
    <DialogContent>
     <Grid container spacing={ 2 }>
      <Grid item md={ 6 }>
       <TextField
        id="opening-time"
        label="Abertura"
        variant="outlined"
        size="small"
        type="time"
        defaultValue="07:30"
        fullWidth
        InputLabelProps={ {
         shrink: true,
        } }
        inputProps={ {
         step: 300, // 5 min
        } }
        value={ opening_time }
        onChange={ (event) => {
         const changes = {
          ...clinicSetting,
          opening_time: formatISO(parse(event.target.value, HOURS_MINUTES_FORMAT, new Date())),
          //opening_time: dateX(event.target.value, HOURS_MINUTES_FORMAT, ISO_FORMAT)
         }
         this.setClinicSettingChanges(changes)
        } }
       />
      </Grid>
      <Grid item md={ 6 }>
       <TextField
        id="closing-time"
        label="Fechamento"
        variant="outlined"
        size="small"
        type="time"
        defaultValue="18:00"
        fullWidth
        InputLabelProps={ {
         shrink: true,
        } }
        inputProps={ {
         step: 300, // 5 min
        } }
        value={ closing_time }
        onChange={ (event) => {
         const changes = {
          ...clinicSetting,
          closing_time: formatISO(parse(event.target.value, HOURS_MINUTES_FORMAT, new Date())),
         }
         this.setClinicSettingChanges(changes)
        } }
       />
      </Grid>
      <Grid item md={ 12 }>
       <CustomField
        fullWidth
        variant="outlined"
        label="Dias de funcionamento"
        components={ WeekChips }
        componentsProps={ {
         wrapperClass: classes.weekdayswrapper,
         days: work_week_days, onChangeDays: this.setWorkWeekDays
        } }
       />

      </Grid>

      <Grid item md={ 12 }>
       <Section title={ "Criando agendamento" }>
        <RadioGroup
         aria-label="appointments_times"
         value={ appt_only_on_clinic_opening_days_times }
         defaultValue={ false }
         onChange={ (event) => {
          const changes = {
           ...clinicSetting,
           appt_only_on_clinic_opening_days_times: event.target.value
          }
          this.setClinicSettingChanges(changes)
         } }>
         <FormControlLabel className={ classes.option } value={ true }
          control={ <Radio /> } label="Apenas nos dias e horários de funcionamento da clínica" />
         <FormControlLabel className={ classes.option } value={ false }
          control={ <Radio /> } label="Qualquer horário" />
        </RadioGroup>
       </Section>
      </Grid>

      <Grid item md={ 12 }>
       <Section title={ "Finalizando agendamento" }>

        <FormControlLabel
         control={ <Checkbox checked={ appt_revenue_allow_deferred_payment }
          onChange={ (event) => {
           this.setAllowRevenueDeferredPayment(event.target.checked)
          } }
          name="allow_deferred_payment" /> }
         label="Permitir pagamento à prazo"
         style={ { margin: 0 } }
        />
        <TextField
         label="Máximo de parcelas"
         type="number"
         variant="outlined"
         margin="dense"
         disabled={ !appt_revenue_allow_deferred_payment }

         value={ appt_revenue_max_parcels }
         onChange={ (event) => {
          const changes = {
           ...clinicSetting,
           appt_revenue_max_parcels: event.target.value
          }
          this.setClinicSettingChanges(changes)
         } }

         InputLabelProps={ {
          shrink: true,
         } }


        />

       </Section>

      </Grid>

     </Grid>
    </DialogContent>
    <DialogActions>
     <Button onClick={ onClose } color="secondary">
      Cancelar
     </Button>
     <Button onClick={ (event) => {
      //onSave(clinic, origUsers)
      onCommit(clinic, origUsers, null)
     } }
      color="primary">
      Salvar
     </Button>
    </DialogActions>
   </Dialog>
  )
 }

 createClinicSettingAndRefreshUI() {


  this.createClinicSetting().then(setting => {

   const changes = { ...setting }

   console.log("*** createClinicSettingAndRefreshUI() - changes", JSON.stringify(changes))

   this.setClinicSettingChanges(changes)
  })
   .catch(err => console.log(err))
 }

 createClinicSetting() {
  const { clinicData } = this.props
  const { clinicChanges } = this.state

  const clinic = {
   ...clinicData,
   ...clinicChanges
  }

  return new Promise((resolve, reject) => {
   const setting = {
    clinic_id: clinic.id,
    professional_id: null,
    user_id: null,
    opening_time: formatISO(parse('08:00', HOURS_MINUTES_FORMAT, new Date())),
    closing_time: formatISO(parse('18:00', HOURS_MINUTES_FORMAT, new Date())),
    work_week_days: '1,2,3,4,5',
    appt_only_on_clinic_opening_days_times: false,
    appt_revenue_allow_deferred_payment: true,
    appt_revenue_max_parcels: 3,
    description: 'Teste'
   }

   resolve(setting)
  })
 }

 setWorkWeekDays(days) {
  const { clinicData } = this.props
  const { clinicChanges } = this.state

  const clinic = {
   ...clinicData,
   ...clinicChanges
  }

  const settings = clinic.settings
  const clinicSetting = settings ? settings.find(setting => setting.professional_id == null) : null

  const changes = {
   ...clinicSetting,
   work_week_days: Array.from(days).join(",")
  }

  this.setClinicSettingChanges(changes)
 }

 setClinicChanges(changes) {
  this.setState({
   clinicChanges: changes
  })

  console.log("*** Settings.setClinicChanges - changes", JSON.stringify(changes))
 }

 setClinicSettingChanges(settingChanges) {
  const { clinicData } = this.props
  const { clinicChanges } = this.state

  const clinic = {
   ...clinicData,
   ...clinicChanges
  }

  

  const settings = clinic.settings || []
  const hasClinicSetting = settings ? settings.find(setting => setting.professional_id == null) : false

  const changes = {
   ...clinicChanges,
   settings: hasClinicSetting ?
    settings.map(setting =>
     (setting.professional_id == null) ? settingChanges : setting
    ) :
    [...settings, { ...settingChanges }]
  }

  console.log("*** Settings.setClinicSettingChanges - settingChanges", JSON.stringify(settingChanges))
  console.log("*** Settings.setClinicSettingChanges - changes", JSON.stringify(changes))



  this.setClinicChanges(changes)

 }

 onSaveTest(clinic, origUsers) {
  console.log("*** Settings.onSaveTest - clinic: ", JSON.stringify(clinic))
  console.log("*** Settings.onSaveTest - origUsers: ", JSON.stringify(origUsers))

 }

 setAllowRevenueDeferredPayment(allow) {
  const { clinicData } = this.props
  const { clinicChanges } = this.state

  const clinic = {
   ...clinicData,
   ...clinicChanges
  }

  const settings = clinic.settings
  const clinicSetting = settings ? settings.find(setting => setting.professional_id == null) : null

  const appt_revenue_max_parcels = clinicSetting ? clinicSetting.appt_revenue_max_parcels : 1

  const changes = {
   ...clinicSetting,
   appt_revenue_allow_deferred_payment: allow,
   appt_revenue_max_parcels: allow ? appt_revenue_max_parcels : 1
  }

  this.setClinicSettingChanges(changes)
 }
}

export default withStyles(settingStyles)(Settings)