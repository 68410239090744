import React from 'react';
import { IconButton, Icon } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import DataGridToolBar from './DataGridToolBar'

const Action = (props) => {

 const { onClick, icon, params } = props

 return (
  <IconButton onClick={ () => onClick(params) }>
   <Icon>{ icon }</Icon>
  </IconButton>
 )
}

export class CustomDataGrid extends React.Component {

 constructor(props) {
  super(props)

  const { actions, columns } = props
  this.columns = [...columns]
  this.freeActions = []

  if (actions) {
   for (const action of actions) {
    if (action.render) {
     if (action.isFreeAction) {
      this.freeActions.push(
       {
        render: action.render
       }
      )
     }
     else {
      this.columns.push(
       {
        field: action.icon,
        width: 50,
        align: 'center',
        renderCell: (cellParams) => action.render(cellParams)
       }
      )
     }

    }
    else if (action.onClick) {
     if (action.isFreeAction) {
      this.freeActions.push(
       {
        render: () =>
         <Action onClick={ action.onClick } icon={ action.icon } />
       }
      )
     }
     else {
      this.columns.push(
       {
        field: action.icon,
        width: 50,
        align: 'center',
        renderCell: (cellParams) =>
         <Action onClick={ action.onClick } icon={ action.icon }
          params={ cellParams } />
       }
      )
     }
    }
   }
  }

  this.state = {
   dataFiltered: props.data,

   filterDefs: (props.options && props.options.filterDefs) ? props.options.filterDefs.map(filterDef => (
    { ...filterDef, value: null }
   )) : null,

   searchString: ''
  }

  this.onFilterData = this.onFilterData.bind(this)
  this.filterData = this.filterData.bind(this)
  this.setFilterValue = this.setFilterValue.bind(this)
  this.getFilterValue = this.getFilterValue.bind(this)
 }

 getFilterValue(id) {
  const { filterDefs } = this.state

  return filterDefs.find(filterDef => filterDef.id == id).value
 }

 setFilterValue(id, value) {
  const { filterDefs } = this.state

  const newFilterDefs = [...filterDefs]

  newFilterDefs.find(filterDef => filterDef.id == id).value = value

  this.setState({
   filterDefs: newFilterDefs
  })

  this.filterData(newFilterDefs)
 }

 onFilterData(criteria) {
  const { data, options } = this.props
  const column = options.search.columns

  this.setState(
   {
    dataFiltered: data.filter(datum => {
     return datum[column].toLocaleLowerCase().includes(criteria.toLocaleLowerCase())
    }),
    searchString: criteria
   }
  )
 }

 filterData(filterDefs) {
  const { data } = this.props

  let dataFiltered = [...data]

  filterDefs && filterDefs.forEach(filterDef => {
   if (filterDef.value) {
    dataFiltered = dataFiltered.filter(datum => {

     return filterDef.expression(datum[filterDef.column], filterDef.value)
    })
   }
  })

  this.setState(
   {
    dataFiltered: dataFiltered
   }
  )
 }

 componentDidUpdate(prevProps) {
  const { data } = this.props
  const { filterDefs } = this.state


  if (prevProps.data != data) {
   /* this.setState((state, props) => {
    return { dataFiltered: data }
   }) */
   this.filterData(filterDefs)
  }
 }

 render() {
  const { actions, columns, title, options } = this.props;
  const { dataFiltered, searchString } = this.state

  console.log("*** CustomDataGrid.render - columns: ", JSON.stringify(this.columns))

  /* let freeActions = []

  if (actions) {
   for (const action of actions) {
    if (action.render) {
     if (action.isFreeAction) {
      freeActions.push(
       {
        render: action.render
       }
      )
     }
     else {
      columns.push(
       {
        field: action.icon,
        width: 50,
        align: 'center',
        renderCell: (cellParams) => action.render(cellParams)
       }
      )
     }

    }
    else if (action.onClick) {
     if (action.isFreeAction) {
      freeActions.push(
       {
        render: () =>
         <Action onClick={ action.onClick } icon={ action.icon } />
       }
      )
     }
     else {
      columns.push(
       {
        field: action.icon,
        width: 50,
        align: 'center',
        renderCell: (cellParams) =>
         <Action onClick={ action.onClick } icon={ action.icon }
          params={ cellParams } />
       }
      )
     }
    }
   }
  } */


  /* const optionsEx = {
   export: {
    csvOptions: {
     fields: ['invoice_emission_date', 'account_code', 'invoice_value_for_export',
      'supplier_name', 'supplier_cnpj', 'invoice_number'],
     fileName: 'Expenses',
     delimiter: ';'
    }
   },

   filterDefs: [
    {
     id: "filter1",
     column: "column1",
     active: boolean,
     operator: Operator,

     render: (getFilterValue, setFilterValue) =>
      <MuiPickersUtilsProvider utils={ DateFnsUtils }>
       <KeyboardDatePicker
        views={ ["year", "month", "date"] }
        value={ getFilterValue("filter1") }
        onChange={ (date, value) => { setFilterValue("filter1", value) } }
        required
        format={ "dd/MM/yyyy" }
        label="Nascimento"
       />
      </MuiPickersUtilsProvider>
    }

   ],

   search: {
    placeholder: 'Pesquisar pelo nome',
    columns: 'name'
   }
  } */

  return (
   <DataGrid
    { ...this.props }

    disableColumnMenu
    rows={ dataFiltered }
    columns={ this.columns }
    components={ { Toolbar: DataGridToolBar } }
    componentsProps={
     {
      toolbar:
      {
       title: title,
       actions: this.freeActions,
       options: options,
       onFilter: this.onFilterData,
       onGetFilterValue: this.getFilterValue,
       onSetFilterValue: this.setFilterValue,
       searchString: searchString
      }
     }
    }
   />
  )
 }
}

