const initialState = {
 professional_id: 0
}

export const professionalReducer = (state = initialState, action) => {
 switch (action.type) {
  case 'CHANGE_PROFESSIONAL':
   return {
    ...state,
    professional_id: action.professional.id,
    professional_name: action.professional.name,
    professional_cpf: action.professional.cpf
   }
  case 'LOGOUT':
   return {
    ...state,
    professional_id: null,
    professional_name: null,
    professional_cpf: null
   }
  default:
   return state;
 }
}