import { TextField, InputAdornment, IconButton,  Icon } from '@mui/material'
import React from 'react'

export default class InputNumber extends React.Component {
 constructor(props) {
  super(props)

  this.state = {
   value: props.initialValue
  }

  this.controlledMode = (typeof (props.value) !== 'undefined') &&
   (typeof (props.onChange) !== 'undefined')

  this.increment = this.increment.bind(this)
  this.decrement = this.decrement.bind(this)
  this.changeValue = this.changeValue.bind(this)
 }

 increment() {
  const { step } = this.props
  const { value } = this.controlledMode ? this.props : this.state

  this.changeValue(value + step)
 }

 decrement() {
  const { step } = this.props
  const { value } = this.controlledMode ? this.props : this.state

  this.changeValue(value - step)
 }

 changeValue(nextValue) {
  const { onChange, maximum, minimum, value } = this.props


  let outOfBounds = false

  if (minimum)
   outOfBounds = (nextValue < minimum)

  if (maximum)
   outOfBounds = outOfBounds || (nextValue > maximum)

  if (this.controlledMode) {
   onChange(outOfBounds ? value : nextValue)
  }
  else {
   this.setState((state, props) => {
    return { value: (outOfBounds ? state.value : nextValue) }
   })
  }
 }

 render() {
  const value = this.controlledMode ? this.props.value : this.state.value
  const { onChange, minimum } = this.props

  return (
   <TextField
    { ...this.props }
    InputProps={ {
     startAdornment:
      <InputAdornment position="start">
       <IconButton onClick={ () => this.decrement() }><Icon>remove</Icon></IconButton>
      </InputAdornment>,
     endAdornment:
      <InputAdornment position="end">
       <IconButton onClick={ () => this.increment() }><Icon>add</Icon></IconButton>
      </InputAdornment>,
     inputMode: 'numeric', pattern: '[0-9]*'
    } }
    inputProps={ { style: { textAlign: 'center' } } }
    value={ value }
    onChange={ (event) => {
     let value = parseInt(event.target.value) || minimum || 0
     this.changeValue(value)
    } }
   />
  )
 }
}