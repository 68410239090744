import React from "react";
import SimpleDialog from "./SimpleDialog";


export default class DeleteDialog extends React.Component {

 constructor(props) {
  super(props)

  this.show = this.show.bind(this)
  this.dialogRef = React.createRef()
 }

 show(subject) {
  this.dialogRef.current.show(subject)
 }

 render() {
  const { onDelete } = this.props

  return (
   <SimpleDialog
    ref={ this.dialogRef }
    message="Deseja realmente excluir?"
    confirm_button="Sim"
    dismiss_button="Não"
    onConfirm={ onDelete }
   />
  )
  /*return (
   <Dialog
    open={ open }
    onClose={ hide }
    aria-labelledby="delete-dialog-title"
    aria-describedby="delete-dialog-description"
   >
    <DialogContent>
     <DialogContentText id="alert-dialog-description">
      Deseja realmente excluir?
     </DialogContentText>
    </DialogContent>
    <DialogActions>
     <Button onClick={ (event) => {
      hide();
      onDelete(entity)
     } }
      color="primary" >
      Sim
     </Button>
     <Button onClick={ hide } color="primary" autoFocus>
      Não
     </Button>
    </DialogActions>
   </Dialog>
  )*/
 }
}