import Axios from "axios"
import { createHashHistory } from 'history';


const TOKEN_KEY = 'user-token'
const baseURL = process.env.REACT_APP_BASE_URL

/* export const getRequest = (url, params = '') => {
 setHeaders(getValidToken());
 if (params != '') {
  return api.get('/' + url + '?' + params)
 }
 return api.get('/' + url)
}

export const postRequest = (url, data, params = '') => {
 setHeaders(getValidToken());
 if (params != '') {
  return api.post('/' + url + '?' + params, data)
 }
 return api.post('/' + url, data)
}

export const deleteRequest = (url, params = '') => {
 setHeaders(getValidToken());
 if (params != '') {
  return api.delete('/' + url + '?' + params)
 }
 return api.delete('/' + url)
}

export const inactiveRequest = (url, id = 0) => {
 setHeaders(getValidToken())
 if (id != 0) {
  return api.patch('/' + url + '/' + id)
 }
 return null
}

export const patchRequest = (url, data, params = '') => {
 setHeaders(getValidToken());
 if (params != '') {
  return api.patch('/' + url + '?' + params, data)
 }
 return api.patch('/' + url, data)
} */

function getAxiosConfig() {
 let axiosConfig = {
  baseURL: baseURL
 }

 const authToken = localStorage.getItem(TOKEN_KEY)

 if (authToken !== null) {
  axiosConfig.headers = {
   Authorization: `Bearer ${authToken}`
  }
 }

 return axiosConfig
}

function setInterceptor(axiosInstance) {
 axiosInstance.interceptors.response.use(
  function (response) {
   // Any status code that lie within the range of 2xx cause this function to trigger
   // Do something with response data
   return response
  }, function (error) {
   // Any status codes that falls outside the range of 2xx cause this function to trigger
   // Do something with response error
   if (error.response.status == 401)
   {
    console.log("Axios Interceptor: Unauthorized")
    localStorage.removeItem(TOKEN_KEY);
    createHashHistory().push('/login')
   }
   return Promise.reject(error)
  }
 )
}


export const getRequest = (url, params = '') => {

 const api = Axios.create(getAxiosConfig());
 setInterceptor(api)

 if (params != '') {
  return api.get('/' + url + '?' + params)
 }
 return api.get('/' + url)
}

export const postRequest = (url, data, params = '') => {

 const api = Axios.create(getAxiosConfig());
 setInterceptor(api)

 if (params != '') {
  return api.post('/' + url + '?' + params, data)
 }
 return api.post('/' + url, data)
}

export const deleteRequest = (url, params = '') => {

 const api = Axios.create(getAxiosConfig());
 setInterceptor(api)

 if (params != '') {
  return api.delete('/' + url + '?' + params)
 }
 return api.delete('/' + url)
}

export const patchRequest = (url, data, params = '') => {

 const api = Axios.create(getAxiosConfig());
 setInterceptor(api)

 if (params != '') {
  return api.patch('/' + url + '?' + params, data)
 }
 return api.patch('/' + url, data)
}
