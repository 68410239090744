import { combineReducers } from 'redux'

import { authReducer } from './authReducer'
import { professionalReducer } from './professionalReducer'
import { userReducer } from './userReducer'

export const Reducers = combineReducers({
  authReducer: authReducer,
  userReducer: userReducer,
  professionalReducer: professionalReducer
})