import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { connect } from 'react-redux';
import { getHomeContent, login, Role } from '../../requests/auth';
import { userAuthActionCreator } from '../../actions';


function Copyright(props) {
 return (
  <Typography variant="body2" color="text.secondary" align="center" { ...props }>
   { 'Copyright © ' }
   <Link color="inherit" href="https://yudoc.com.br">
    Yudoc
   </Link>{ ' ' }
   { new Date().getFullYear() }
   { '.' }
  </Typography>
 );
}

// TODO remove, this demo shouldn't need to reset the theme.

const defaultTheme = createTheme();

function SignIn(props) {
 const handleSubmit = (event) => {
  event.preventDefault();
  const data = new FormData(event.currentTarget);

  const email = data.get('email')
  const password = data.get('password')

  const loginData = {
   system_admin: {
    email: email,
    password: password,
   },
  };

  if (!email || !password) {
   alert('Preencha e-mail e senha para continuar!');
  }
  else {

   login(loginData)
    .then(res => {
     props.setAuthenticatedUser(res)
     props.history.push(getHomeContent());
    })
    .catch(err => console.error(err))
  }
 };

 return (
  <ThemeProvider theme={ defaultTheme }>
   <Container component="main" maxWidth="xs">
    <CssBaseline />
    <Box
     sx={ {
      marginTop: 8,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
     } }
    >
     <Avatar sx={ { m: 1, bgcolor: 'secondary.main' } }>
      <LockOutlinedIcon />
     </Avatar>
     <Typography component="h1" variant="h5">
      Sign in
     </Typography>
     <Box component="form" onSubmit={ handleSubmit } noValidate sx={ { mt: 1 } }>
      <TextField
       margin="normal"
       required
       fullWidth
       id="email"
       label="Email Address"
       name="email"
       autoComplete="email"
       autoFocus
      />
      <TextField
       margin="normal"
       required
       fullWidth
       name="password"
       label="Password"
       type="password"
       id="password"
       autoComplete="current-password"
      />
      <FormControlLabel
       control={ <Checkbox value="remember" color="primary" /> }
       label="Remember me"
      />
      <Button
       type="submit"
       fullWidth
       variant="contained"
       sx={ { mt: 3, mb: 2 } }
      >
       Sign In
      </Button>

     </Box>
    </Box>
    <Copyright sx={ { mt: 8, mb: 4 } } />
   </Container>
  </ThemeProvider>
 );
}

const mapStateToProps = store => {
 return {
  user_id: store.authReducer.user_id,
  email: store.authReducer.email
 };
}

const mapDispatchToProps = dispatch => {
 return {
  onLogin: () => dispatch({ type: 'LOGIN' }),
  setAuthenticatedUser: value => dispatch(userAuthActionCreator(value))
 };
}

export default connect(mapStateToProps, mapDispatchToProps)(SignIn);
