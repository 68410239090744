import React from 'react'
import { Grid, Button, Icon } from '@mui/material'
import { GridToolbarContainer } from '@mui/x-data-grid'

const newUserTemplate = (role) => ({ name: '', email: '', role: role })


class UsersGridToolbar extends React.Component {
 constructor(props) {
  super(props)
 }


 render() {
  const { onChange, onRestore, role } = this.props

  return (
   <GridToolbarContainer>
    <div style={ { display: 'flex', justifyContent: 'end', gap: '5px', alignItems: 'center', width: '100%' } }>

     <Button
      variant="contained"
      color="primary"
      size="small"
      startIcon={ <Icon>add_circle</Icon> }
      onClick={ () => onChange([newUserTemplate(role)]) }
      style={ { whiteSpace: 'nowrap' } }
     >
      Adicionar
     </Button>

     {
      onRestore &&
      <Button
       variant="contained"
       color="primary"
       size="small"
       startIcon={ <Icon>refresh</Icon> }
       onClick={ () => onRestore() }
       style={ { whiteSpace: 'nowrap' } }
      >
       Restaurar
      </Button>
     }


    </div>
   </GridToolbarContainer>
  )

 }
}

export default UsersGridToolbar