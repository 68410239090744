import { addHours, format, parse } from "date-fns"


export const dateX = (dateString, inputFormat, outputFormat,
 tzOffset = new Date().getTimezoneOffset() / 60) => {
 const dateRef = new Date()

 const birthDateParsed = parse(dateString, inputFormat, dateRef)

 if (birthDateParsed instanceof Date && !isNaN(birthDateParsed.getTime())) {
  return format(addHours(birthDateParsed, tzOffset), outputFormat)
 }
 else {
  return dateString
 }
}

export const HOURS_MINUTES_FORMAT = 'HH:mm'
export const ISO_FORMAT = 'yyyy-MM-dd HH:mm:ss'