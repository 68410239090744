import React from "react";
import {
 FilledInput,
 FormControl,
 FormHelperText,
 Input,
 InputLabel,
 OutlinedInput,
 Typography,
} from "@mui/material";

import {
 makeStyles
} from "@mui/styles"

export const customFieldDefaultStyles = makeStyles({
 "CustomField-label": {
  zIndex: 2
 },
 "CustomField-labelnotch": props => ({
  float: "left",
  top: 0,
  left: 0,
  position: "absolute",
  width: props.label ? `${props.label.length * 8}px` : 0,
  height: "15px",
  backgroundColor: "#ffffff",
  transform: "translate(14px, -6px)",
  zIndex: 1
 }),
 "CustomField-container": props => ({
  color: "rgba(0, 0, 0, 0.87)",
  border: "solid 1px",
  cursor: "text",
  borderRadius: "4px",
  borderColor: props.error ? "#ff0000" : "#bbbbbb",
  display: "inline-flex",
  position: "relative",
  fontSize: "1rem",
  boxSizing: "border-box",
  alignItems: "center",
  fontFamily: "Roboto, Helvetica, Arial, sans-serif",
  fontWeight: 400,
  lineHeight: "1.1876em",
  letterSpacing: "0.00938em",
  '&:hover': props.error ? {
   borderColor: "#ff0000"
  } : {
   borderColor: "#000000"
  },
  /* '& input:focus': {
   border: 'solid 2px',
   borderColor: props.error ? '#ff0000' : '#0000ff',
   borderRadius: "4px",
   boxSizing: "border-box"
  } */
 }),
 /* "CustomField-formcontrol": {
  '& input:focus': {
   color: 'blue',
   border: 'solid 10px #0000ff'
  } 
 } */
});

export default function CustomField(props) {

 const variant = props.variant
 const fullWidth = props.fullWidth
 const classes = customFieldDefaultStyles(props)

 const Components = props.components
 const componentsProps = props.componentsProps
 const classNames = ['.MuiInputBase-input']

 if (props.size == "small")
  classNames.push('.MuiInputBase-inputMarginDense')

 let RootComponent

 switch (variant) {
  case 'outlined':
   RootComponent = OutlinedInput
   classNames.push('.MuiOutlinedInput-input')
   if (props.size == "small")
    classNames.push('.MuiOutlinedInput-inputMarginDense')
   break

  case 'filled':
   RootComponent = FilledInput
   classNames.push('.MuiFilledInput-input')
   if (props.size == "small")
    classNames.push('.MuiFilledInput-inputMarginDense')
   break

  default:
   RootComponent = Input
  //classNames.push('.MuiInputBase-input' + ((props.size == "small") ? 'MarginDense' : ''))

 }

 console.log("### CustomField - classNames", JSON.stringify(classNames))

 return (
  <FormControl error={ props.error || false } variant={ props.variant } size={ props.size || "medium" } fullWidth={ fullWidth }>

   { (props.variant == "outlined") &&
    <div id="labelnotch" className={ `${classes["CustomField-labelnotch"]}` }></div> }


   <InputLabel htmlFor="components-container" shrink>
    { props.label }
   </InputLabel>

   <RootComponent
    id="components-container"
    inputComponent={ Components }
    inputProps={ { ...componentsProps, classNames: classNames } }
    label={ props.label }
    size={ props.size }
   />

   {
    props.helperText &&

    <FormHelperText id="component-helper-text">
     {
      props.helperText
     }
    </FormHelperText>
   }
  </FormControl >
 );
}


/* export default function CustomField(props) {
 const classes = props.classes(props)

 const Components = props.components
 const componentsProps = props.componentsProps

 return (
  <FormControl className={ `${classes["CustomField-formcontrol"]}` } error={ props.error || false }>
   { (props.variant == "outlined") &&
    <div id="labelnotch" className={ `${classes["CustomField-labelnotch"]}` }></div> }

   <InputLabel className={ `${classes["CustomField-label"]}` }
    variant={ props.variant } htmlFor="group-count-input" shrink>
    { props.label }
   </InputLabel>

   <div className={ `${classes["CustomField-container"]}` }
    style={ {
     display: "inline-flex",
     alignItems: "center",
     gap: "5px",
     padding: "0px 10px 0px 10px"
    } }
   >
    <Components { ...componentsProps } />
   </div>

   {
    props.helperText &&

    <FormHelperText id="component-helper-text">
     {
      props.helperText
     }
    </FormHelperText>
   }
  </FormControl>
 );
} */

