import decodeJWT from 'jwt-decode';
import { deleteRequest, postRequest } from "./requests";
import { createHashHistory } from 'history';


export const TOKEN_KEY = 'user-token';

export const Role = {
 Admin: 'Administrator',
 Professional: 'Professional',
 Secretary: 'Secretary',
 SystemAdmin: 'System Administrator',
 Accounter: 'Accounter'
}

export function getUserRole() {
 return localStorage.getItem('role')
}

export const isAuthenticated = () => localStorage.getItem(TOKEN_KEY) !== null;


export function getHomeContent(role) {
 /* switch (role) {
  case Role.Accounter:
   return '/despesas-profissional'
  case Role.Admin:
  case Role.Secretary:
  case Role.Professional:
  default:
   return '/calendario'
 } */
 return '/clinicas'
}


export function login(data) {
 return postRequest('admins/sign_in', data).then(response => {
  localStorage.setItem('user_id', response.data.id);
  localStorage.setItem('name', response.data.name);
  localStorage.setItem('email', response.data.email);
  //localStorage.setItem('clinic_id', response.data.clinics[0].id);
  //localStorage.setItem('role', response.data.role);

  let token = extractToken(response)

  rememberToken(token);
  return getDecodedToken(token);
 })
  .catch((error) => {
   if (/ 401/.test(error.message)) {
    alert('Credenciais Incorretas. Tente Novamente!');
   }
  });
}

export function logout() {

 let history = createHashHistory();

 return deleteRequest('users/sign_out').then(() => {
  rememberToken(null);
  return null;
 }).catch(err => {
  if (err.response.status == 401) {
   history.push('/login')
  }
 })
}

export function getDecodedToken(token) {
 if (token) {
  return decodeJWT(token);
 }

 return null;
}

function extractToken(res) {
 return res.headers.authorization.split(' ')[1];
}

export function rememberToken(token) {
 if (token) {
  // Remember the token
  localStorage.setItem(TOKEN_KEY, token);
 } else {
  // Clear the remembered token: sign out
  localStorage.removeItem(TOKEN_KEY);
 }
}
