import React from "react";
import { Button } from "@mui/material";

/*
Componente auxiliar baseado em função a ser usado como possível valor da propriedade 'actions' de LayoutDialog.
Apresenta um botão de cancelamento (dismiss_button), focalizado por default, e um botão de confirmação
(confirm_button)
*/

const LayoutDialogActions = (props) => {
 const { confirm_button, dismiss_button, onAction, onDismiss, hide, target } = props

 return (
  <>
   <Button onClick={ (event) => {
    hide();
    onDismiss && onDismiss()
   } }
    color="primary" autoFocus>
    { dismiss_button }
   </Button>

   <Button onClick={ (event) => {
    hide();
    onAction(target)
   } }
    color="primary" >
    { confirm_button }
   </Button>
  </>
 )
}

export default LayoutDialogActions