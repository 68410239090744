import { makeStyles } from "@mui/styles";

const inputStyles = makeStyles({
 ".MuiInputBase-input": {
  font: "inherit",
  color: "currentColor",
  width: "100%",
  border: 0,
  outline: 'none',
  height: "1.1876em",
  margin: 0,
  display: "block",
  padding: "6px 0 7px",
  minWidth: 0,
  background: "none",
  boxSizing: "content-box",
  animationName: "mui-auto-fill-cancel",
  letterSpacing: "inherit",
  animationDuration: "10ms",
  webkitTapHighlightColor: "transparent"
 },
 ".MuiInputBase-inputMarginDense": {
  paddingTop: "3px"
 },
 ".MuiOutlinedInput-input": {
  padding: "18.5px 14px"
 },
 ".MuiOutlinedInput-inputMarginDense": {
  paddingTop: "10.5px",
  paddingBottom: "10.5px"
 },
 ".MuiFilledInput-input": {
  padding: "27px 12px 10px"
 },
 ".MuiFilledInput-inputMarginDense": {
  paddingTop: "23px",
  paddingBottom: "6px"
 }
})

export default inputStyles