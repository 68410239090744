import React
 from "react";
import { Box, Typography } from "@mui/material";

class TabPanel extends React.Component {
 constructor(props) {
  super(props)
 }

 render() {
  const { children, value, index, ...other } = this.props

  return (
   <div
    role="tabpanel"
    hidden={ value !== index }
    id={ `simple-tabpanel-${index}` }
    aria-labelledby={ `simple-tab-${index}` }
    { ...other }
   >

    <Box>
     <Typography>{ children }</Typography>
    </Box>
   </div>
  );
 }
}

export default TabPanel