export const changeProfessionalActionCreator = value => ({
 type: 'CHANGE_PROFESSIONAL',
 professional: value
})

export const userAuthActionCreator = jwtToken => ({
 type: 'USER_AUTH_ACTION',
 user_id: jwtToken.sub,
 email: jwtToken.email,
 name: jwtToken.name,
})

export const currentClinicActionCreator = clinicId => (
 {
  type: 'CURRENT_CLINIC_ACTION',
  clinic_id: clinicId
 }
)