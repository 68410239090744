import React from 'react'
import MaskedInput from 'react-text-mask'

export function phoneMask(props) {
  const { inputRef, ...other } = props

  return (
    <MaskedInput
      {...other}
      /* ref={(ref) => {
        inputRef(ref ? ref.inputElement : null)
      }} */
      mask={["(", /[0-9]/, /[0-9]/, ")", " ",
              /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/,
              '-', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/
      ]}
    />
  )
}