import React from 'react'
import MaskedInput from 'react-text-mask'

export function cepMask(props) {
  const { inputRef, ...other } = props
// 22041-011
  return (
    <MaskedInput
      {...other}
      /* ref={(ref) => {
        inputRef(ref ? ref.inputElement : null)
      }} */
      mask={[/[0-9]/, /[0-9]/, /[0-9]/,
             /[0-9]/, /[0-9]/, '-',
             /[0-9]/, /[0-9]/, /[0-9]/
      ]}
    />
  )
}